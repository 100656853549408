<template>
  <div class="login-container">
    <el-form ref="form" :model="form" :rules="rules">
      <el-form-item prop="username">
        <el-input
          prefix-icon="el-icon-user-solid"
          v-model="form.username"
          placeholder="请输入登录名"
        ></el-input>
      </el-form-item>
      <el-form-item prop="password">
        <el-input
          type="password"
          prefix-icon="el-icon-lock"
          v-model="form.password"
          placeholder="请输入密码"
        ></el-input>
      </el-form-item>
    </el-form>
    <el-button :disabled="loading" block type="primary" @click="login"
      >登 录</el-button
    >
  </div>
</template>

<script>
export default {
  data() {
    return {
      loading: false,
      form: {
        username: "",
        password: "",
      },
      rules: {
        username: [
          { required: true, message: "请输入用户名", trigger: "blur" },
        ],
        password: [{ required: true, message: "请输入密码", trigger: "blur" }],
      },
    }
  },
  methods: {
    login() {
      this.$refs.form.validate((valid) => {
        if (valid) {
          this.$api.login(this.form).then(() => {
            // 登录成功后跳转到首页
            this.$router.push("/admin/adminHome")
          })
        }
      })
    },
  },
}
</script>

<style lang="scss">
.login-container {
  width: 800px;
  margin: 150px auto;
  .el-form-item {
    margin-bottom: 40px;
    .el-form-item__error {
      font-size: calc(var(--font-size) - 4px);
    }
  }
  .el-button {
    border-radius: $--input-border-radius;
    height: $--input-medium-height;
    span {
      font-size: calc(var(--font-size) + 4px);
    }
  }
  .el-input {
    .el-input__inner{
      padding-left: 60px;
    }
    i{
      padding-left: 10px;
      font-size: calc(var(--font-size) + 4px);
    }
  }
}
</style>
