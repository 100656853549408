<template>
  <div class="product-list">
    <template v-if="$route.path == '/product'">
      <div class="banner">
        <!-- <h1>{{ $t("product.title") }}<br />{{ $t("product.refineOn") }}</h1> -->
        <el-image :src="banner"></el-image>
      </div>
      <p class="desc" v-html="$t('product.desc')"></p>
      <div class="msg-list">
        <h3 class="list-title">{{ $t("product.title") }}</h3>
        <!-- <div class="list-item" v-for="(msg, idx) in msgList" :key="idx + '/'">
          <el-image
            class="img"
            :src="require('@/assets/img/' + msg.img)"
          ></el-image>
          <div class="item-main">
            <el-link
              type="primary"
              :underline="false"
              @click="$router.push(msg.link)"
            >
              <span v-html="$t(msg.title)"></span
              ><i class="el-icon-arrow-right"></i><br />{{ $t(msg.subTitle) }}
            </el-link>
            <div class="desc">{{ $t(msg.desc) }}</div>
            <div v-if="msg.tags" class="tags">
              <el-tag v-for="tag in $t(msg.tags).split('_')" :key="tag">{{
                tag
              }}</el-tag>
            </div>
          </div>
        </div> -->
        <template v-if="msgList1 && msgList1.length > 0">
          <div
            class="list-item"
            v-for="(msg, idx) in msgList1"
            :key="idx + '/'"
            @click="$router.push('/productView/' + msg.id)"
          >
            <el-image class="img" :src="path + msg.img"></el-image>
            <div v-if="$i18n.locale == 'zh'" class="item-main">
              <el-link type="primary" :underline="false">
                <span v-html="msg.title_zh"></span>
                <i class="el-icon-arrow-right"></i><br />
                {{ msg.subTitle_zh }}
              </el-link>
              <div class="desc" v-html="msg.content_zh"></div>
              <div v-if="msg.tags_zh" class="tags">
                <el-tag v-for="tag in $t(msg.tags_zh).split('_')" :key="tag">{{
                  tag
                }}</el-tag>
              </div>
            </div>
            <div v-else class="item-main">
              <el-link type="primary" :underline="false">
                <span v-html="msg.title_en"></span>
                <i class="el-icon-arrow-right"></i><br />
                {{ msg.subTitle_en }}
              </el-link>
              <div class="desc" v-html="msg.content_en"></div>
              <div v-if="msg.tags_en" class="tags">
                <el-tag v-for="tag in $t(msg.tags_en).split('_')" :key="tag">{{
                  tag
                }}</el-tag>
              </div>
            </div>
          </div>
        </template>
      </div>
    </template>
    <router-view :key="$route.path" v-else></router-view>
  </div>
</template>

<script>
import routerView from "../routerView.vue";
export default {
  components: { routerView },
  data() {
    return {
      banner: require("@/assets/img/product1.png"),
      msgList1: [],
      path: config.BASEURL,
      msgList: [
        {
          img: "product2.png",
          title: "product1.title1",
          subTitle: "product1.subTitle",
          desc: "product1.desc",
          tags: "product1.tags",
          link: "/product/1",
          id: "111",
        },
        {
          img: "product3.png",
          title: "product2.title1",
          subTitle: "product2.subTitle",
          desc: "product2.desc",
          tags: "product2.tags",
          link: "/product/2",
          id: "111",
        },
        {
          img: "product4.png",
          title: "product3.title1",
          subTitle: "product3.subTitle",
          desc: "product3.desc",
          tags: "product3.tags",
          link: "/product/3",
          id: "111",
        },
        // {
        //   img: "product7.png",
        //   title: "product4.title1",
        //   subTitle: "product4.subTitle",
        //   desc: "product4.desc",
        //   tags: "product4.tags",
        //   link: "/product",
        //   id: "111",
        // },
      ],
    };
  },
  created() {
    this.getBanner();
    this.getLists();
  },
  methods: {
    getBanner() {
      this.$api.getBanner().then((res) => {
        if (res.data) {
          let opt = res.data.find((val) => val.page == "product");
          this.banner = config.BASEURL + opt.img;
        }
      });
    },
    getLists() {
      this.msgList1 = [];
      this.$api.getLists({ page: "productList" }).then((res) => {
        if (res.data) {
          this.msgList1 = res.data;
        }
      });
    },
  },
};
</script>
<style lang="scss">
.product-list {
  .banner {
    position: relative;
    margin-bottom: 30px;
    // h1 {
    //   position: absolute;
    //   left: 260px;
    //   top: 50%;
    //   transform: translateY(-50%);
    //   font-weight: 400;
    //   font-size: calc(var(--font-size) * 1.6);
    //   color: $--color-reverse;
    //   line-height: 81px;
    //   z-index: 10;
    // }
    .el-image {
      width: 100%;
      height: 560px;
    }
  }
  p.desc,
  .msg-list {
    width: var(--page-width);
    margin: 0 auto;
  }
  p.desc {
    font-weight: 300;
    font-size: var(--font-size);
    color: $--color-theme;
    margin-bottom: 110px;
  }
  .msg-list {
    padding-bottom: 120px;
    .img {
      width: 360px;
      min-width: 360px;
      height: 220px;
      margin-right: 50px;
    }
    .list-item {
      padding-top: 50px;
      padding-bottom: 50px;
    }
    .desc {
      font-size: calc(var(--font-size) - 4px);
      margin-top: 30px;
      margin-bottom: 30px;
      * {
        font-size: calc(var(--font-size) - 4px);
      }
    }
  }
  .tags {
    .el-tag {
      height: auto;
      border-radius: 0px;
      font-size: calc(var(--font-size) -8px);
      padding-left: 16px;
      padding-right: 16px;
      padding-top:8px;
      padding-bottom: 4px;
      background: rgba(232, 241, 246, 0);
      border: 1px solid #999999;
    }
    .el-tag {
      margin-right: 10px;
      margin-top: 10px;
      margin-left: 10px;
    }
  }
  .el-link .el-link--inner,
  .el-link span,
  .el-link p {
    font-weight: 400;
    font-size: calc(var(--font-size) + 6px);
    i {
      font-size: calc(var(--font-size) - 4px);
    }
  }
}
.product-list .el-link p {
  font-weight: 400;
  font-size: calc(var(--font-size) + 6px);
  display: inline;
  i {
    font-size: calc(var(--font-size) - 4px);
  }
}
</style>
