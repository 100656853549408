<template>
  <div class="page-content">
    <div class="msg-list">
      <h3 class="list-title">{{ $t("home.msg") }}</h3>
      <div
        class="list-item"
        v-for="(msg, idx) in msgList"
        :key="idx + '/'"
        @click="openPage(msg)"
      >
        <el-image :src="path + msg.img"></el-image>
        <div class="list-item-content">
          <div class="title">
            {{ $i18n.locale == "zh" ? msg.title : msg.title_en }}
          </div>
          <div class="date">
            <div class="date-left">
              <el-tag effect="plain">
                {{ getType(msg.type) }}
              </el-tag>
              <span>
                <i class="el-icon-time"></i
                >{{ formatDate(msg.creattime) }}</span
              >
              <!-- <span>
                <i class="el-icon-view"></i>{{ msg.readcount || "0"
                }}{{ $t("news.view") }}</span
              > -->
            </div>
            <div class="date-right">
              <el-link :underline="false" type="primary" @click="openPage(msg)">
                {{ $t("news.click") }}<i class="el-icon-arrow-right"></i>
              </el-link>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { formatDate } from "@/utils/index"
export default {
  data() {
    return {
      path:config.BASEURL,
      msgList: [],
      typeOptions: [
        { name: "International News", value: "国际新闻" },
        { name: "Company News", value: "公司新闻" },
      ],
    }
  },
  created() {
    this.getList()
  },
  methods: {
    formatDate,

    getType(type) {
      if (!type) return this.$t("other")
      let opt = this.typeOptions.find(
        (item) => item.value == type || item.name == type
      )
      return this.$i18n.locale == "zh" ? opt.value : opt.name
    },
    getList() {
      this.$api.getNews().then((res) => {
        this.msgList = res.data
      })
    },
    openPage(msg) {
      this.$router.push("/news/view?id=" + msg.id)
    },
  },
}
</script>
<style lang="scss" scoped>
.msg-list {
  margin-top: 35px;
}
</style>
