<template>
  <div class="admin-banner">
    <div class="msg-list">
      <h3 class="list-title">{{ $t("banner.title") }}</h3>
      <template v-for="(item, idx) in data">
        <template v-if="item.page.indexOf(page) > -1">
          <h3 v-if="!item.lang1" :key="item.page">
            {{ $t(item.lang) }} Banner
          </h3>
          <h3 v-else :key="item.page + '-'">
            {{ $t(item.lang1) }} - {{ $t(item.lang) }} 图片
          </h3>
          <div :key="item.page" class="list-item justify-center">
            <div class="item-main" style="text-align: center">
              <el-upload
                ref="upload"
                action="#"
                list-type="picture-card"
                :file-list="fileList[item.page]"
                :on-change="
                  (file, fileList) => {
                    onChange(file, fileList, item.page);
                  }
                "
                :auto-upload="false"
              >
                <i slot="default" class="el-icon-plus"></i>
                <div
                  slot="file"
                  class="flex justify-center"
                  slot-scope="{ file }"
                >
                  <el-image
                    :src="file.url"
                    :preview-src-list="[file.url]"
                  ></el-image>
                </div>
              </el-upload>
              <div class="flex justify-center">
                <h3>最佳尺寸:{{ item.img }}</h3>
              </div>
              <div v-if="page == 'home' && idx == 0" :gutter="20">
                <h3>Banner 文字</h3>
                <el-input
                  type="textarea"
                  placeholder="中文"
                  v-model="form[idx].desc_zh"
                  :rows="5"
                ></el-input>
                <el-divider></el-divider>
                <el-input
                  type="textarea"
                  placeholder="英文"
                  v-model="form[idx].desc_en"
                  :rows="5"
                ></el-input>
              </div>
              <div class="flex justify-center">
                <el-button type="primary" @click="submit(item.page, idx)"
                  >保存
                </el-button>
              </div>
            </div>
          </div>
        </template>
      </template>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    page: String,
  },
  data() {
    return {
      form: [],
      disabled: false,
      data: [
        { page: "home", lang: "home.title", img: "1920px * 900px" },
        {
          page: "home.product",
          lang1: "home.title",
          lang: "home.product",
          img: "1200px * 560px",
        },
        {
          page: "home.company",
          lang1: "home.title",
          lang: "home.company",
          img: "1200px * 560px",
        },
        { page: "product", lang: "product.title", img: "1920px * 560px" },
      ],
      fileList: {
        home: [],
        product: [],
        "home.product": [],
        "home.company": [],
      },
    };
  },
  created() {
    this.getBanner();
  },
  methods: {
    delImg(type) {
      this.fileList[type] = [];
    },
    onChange(file, fileList, type) {
      this.fileList[type] = [file];
    },
    getBanner() {
      this.$api.getBanner().then((res) => {
        if (res.data) {
          this.form = res.data;
          res.data.forEach((val) => {
            if (!this.fileList[val.page]) this.fileList[val.page] = [];
            this.fileList[val.page].push({
              img: val.img,
              url: config.BASEURL + val.img,
            });
          });
        }
      });
    },
    async submit(page, idx) {
      if (this.fileList[page].length == 0) {
        this.$message.error("请选择图片");
        return;
      }
      let file = this.fileList[page][0];
      console.log(file);
      let formData = new FormData();
      formData.append("file", file.raw);
      let res = file.raw
        ? await this.$api.uploadFile(formData)
        : {
            data: {
              path: file.img,
            },
          };

      // this.fileList[type][0].url = res.data;
      let id,
        opt = {};
      if (this.form) {
        opt = this.form.find((val) => val.page == page);
        id = opt ? opt.id : null;
      }
      this.$api
        .uploadBanner({
          page,
          type: "banner",
          id,
          img: res.data.path,
          ...opt,
        })
        .then((res) => {
          this.$message.success("上传成功");
        });
    },
  },
};
</script>

<style lang="scss">
.el-upload.el-upload--picture-card {
  margin-bottom: 10px;
}
.el-upload-list--picture-card .el-upload-list__item {
  width: 600px;
  height: 300px;
  .el-image {
    width: 100%;
    height: 100%;
  }
}
</style>
<style lang="less" scoped>
.list-item {
  margin-bottom: 20px;
  h3 {
    padding-top: 10px;
    padding-bottom: 10px;
  }
  .el-button {
    margin-top: 10px;
  }
}
</style>