<template>
  <div>
    <el-page-header @back="goBack" content="回复"> </el-page-header>
    <div class="news-edit">
      <base-form ref="form" :form="form" :formProps="formProps" :isText="true">
        <div slot="notes">
          <el-input
            type="textarea"
            v-model="form.notes"
            :rows="6"
            :maxlength="1000"
          ></el-input>
        </div>
        <template v-if="form && form.type" slot="type">
          {{ typeForm }}
        </template>
      </base-form>
      <div class="flex flex-center">
        <!-- <el-button type="primary" @click="$router.push('/news')">返回</el-button> -->
        <el-button type="primary" @click="submit">保存</el-button>
      </div>
    </div>
  </div>
</template>
  
  <script>
import { zh } from "@/i18n/zh.js";
import { en } from "@/i18n/en.js";
import editor from "@/components/editor";
const path = config.BASEURL;
export default {
  components: {
    editor,
  },
  data() {
    const _this = this;
    return {
      typeOptins: [
        {
          name: en.product1.subTitle,
          value: zh.product1.subTitle,
        },
        {
          name: en.product2.subTitle,
          value: zh.product2.subTitle,
        },
        {
          name: en.product3.subTitle,
          value: zh.product3.subTitle,
        },
        {
          name: "Other Products",
          value: "其他产品",
        },
      ],
      path,
      form: {
        img: "",
      },
      rules: {
        img: [{ required: true, message: "请上传图片", trigger: "blur" }],
        title: [{ required: true, message: "请输入标题", trigger: "blur" }],
        content: [{ required: true, message: "请输入内容", trigger: "blur" }],
        title_en: [
          { required: true, message: "请输入英文标题", trigger: "blur" },
        ],
        content_en: [
          { required: true, message: "请输入英文内容", trigger: "blur" },
        ],
      },
      formProps: {
        companyname: {
          label: _this.$t("contact.companyname"),
          width: "100%",
          type: "input",
        },
        name: {
          label: _this.$t("contact.surname") + _this.$t("contact.name"),
          type: "input",
        },
        phone: {
          label: _this.$t("contact.phone"),
          type: "input",
        },
        country: {
          label: _this.$t("contact.country"),
          type: "input",
        },
        city: {
          label: _this.$t("contact.city"),
          type: "input",
        },
        state: {
          label: _this.$t("contact.state"),
          type: "input",
        },
        zip: {
          label: _this.$t("contact.zip"),
          type: "input",
        },

        address1: {
          width: "100%",
          label: _this.$t("contact.address1"),
          type: "input",
        },
        address2: {
          width: "100%",
          label: _this.$t("contact.address2"),
          type: "input",
        },
        email: {
          label: _this.$t("contact.email"),
          type: "input",
        },
        type: {
          label: _this.$t("contact.type"),
          slotName: "type",
        },
        create_time: {
          label: "创建时间",
          type: "input",
        },
        content: {
          label: _this.$t("contact.content"),
          width: "100%",
          type: "input",
        },
        notes: {
          label: _this.$t("contact.notes"),
          width: "100%",
          slotName: "notes",
        },
      },
    };
  },
  computed: {
    typeForm() {
      let opt = this.typeOptins.find(
        (item) => item.value == this.form.type || item.name == this.form.type
      );
      return this.$i18n.locale == "zh" ? opt.value : opt.name;
    },
  },
  created() {
    this.user = sessionStorage.getItem("user");
    if (this.user) {
      this.user = JSON.parse(this.user);
    }
    this.query = this.$route.query;
    if (this.query.id) {
      this.$api.getUserById({ id: this.query.id }).then((res) => {
        this.form = res.data;
        console.log(this.form);
      });
    }
  },
  methods: {
    goBack() {
      this.$router.replace("/admin/user");
    },
    submit() {
      console.log(this.$refs.form);
      this.$refs.form.validate().then((valid) => {
        if (valid) {
          this.form.status = "3";
          this.$api.saveUser(this.form).then(() => {
            this.$message.success(this.$t("success"));
            this.goBack();
          });
        }
      });
    },
  },
};
</script>
  
  <style lang="scss">
.news-edit {
  .el-form .el-form-item__label {
    float: none;
  }

  .el-input {
    height: 56px;
    line-height: 56px;
    .el-input__inner {
      height: 56px !important;
      line-height: 56px !important;
      font-size: 16px;
    }
    .el-input__suffix {
      display: none;
    }
  }
}
</style>
  