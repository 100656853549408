<template>
  <div class="admin-home">
    <bannerAdmin page="home"/>
    <div class="msg-list">
      <h3 class="list-title">列表管理</h3>
    </div>
    <product path="adminHome"/>
  </div>
</template>

<script>
import bannerAdmin from "./banner.vue";
import product from "../product/index.vue";
export default {
  components: { bannerAdmin ,product},
  data() {
    return {
      tableHeader: [
        {
          label: "序号",
          type: "index",
          width: 55,
        },
        {
          label: "标题",
          prop: "img",
          render: (h, rows) => {
            return h("el-image", { attrs: { src: config.BASEURL + rows.img } });
          },
        },
        {
          label: "标题",
          prop: "title_zh",
          slotName: "title",
        },
        {
          label: "描述",
          prop: "content_zh",
          width: 300,
        },
        {
          label: "操作",
          slotName: "opt",
          fixed: "right",
          width: 200,
        },
      ],
      tableData: [],
    };
  },
};
</script>

<style>
</style>