<template>
  <div>
    <base-table
      :isPagination="false"
      :tableHeader="tableHeader"
      :responses="tableData"
    >
      <template slot="btns">
        <el-button type="success" size="small" @click="open('add')"
          >新增</el-button
        >
        <el-button type="primary" size="small" @click="getList">刷新</el-button>
      </template>
      <span slot="title" slot-scope="{ row }" v-html="row.title_zh"></span>
      <div class="rows-btn" slot="opt" slot-scope="{ row }">
        <el-link type="primary" size="small" @click="open('edit', row.id)">
          编辑
        </el-link>
        <el-link type="danger" size="small" @click="delRow(row.id)">
          删除
        </el-link>
      </div>
    </base-table>
  </div>
</template>

<script>
export default {
  name: "ProductList",
  props: {
    path: {
      type: String,
      default: "productList",
    },
  },
  components: {},
  data() {
    // const _this = this
    return {
      tableHeader: [
        {
          label: "序号",
          type: "index",
          width: 55,
        },
        {
          label: "配图",
          prop: "img",
          render: (h, rows) => {
            return h("el-image", { attrs: { src: config.BASEURL + rows.img } });
          },
        },
        {
          label: "标题",
          prop: "title_zh",
          slotName: "title",
        },
        {
          label: "副标题",
          prop: "subTitle_zh",
          isHide: () => {
            return this.path == "adminHome";
          },
        },
        {
          label: "描述",
          prop: "content_zh",
          width: 300,
        },
        {
          label: "排序",
          prop: "sort",
        },
        {
          label: "操作",
          slotName: "opt",
          fixed: "right",
          width: 150,
        },
      ],
      tableData: [],
    };
  },
  mounted() {
    this.getList();
  },
  methods: {
    getList() {
      this.$api.getLists({ page: this.path }).then((res) => {
        this.tableData = res.data;
      });
    },
    open(type, id) {
      this.$router.push({
        name: "lists",
        query: { type, id, path: this.path },
      });
    },
    delRow(id) {
      this.$confirm("确认删除？", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        // 执行删除操作
        this.$api.deleteLists(id).then(() => {
          this.getList();
          this.$message({
            type: "success",
            message: "删除成功",
          });
        });
      });
    },
  },
};
</script>

<style></style>
