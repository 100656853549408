<template>
  <div class="breadcrumb">
    <div class="breadcrumb-body">
      <template v-if="$route.path != '/'">
        <el-link :underline="false" @click="$router.push('/')">
          {{ $t("home.title") }}
        </el-link>
        <span class="separator">/</span>
      </template>
      <template v-for="(matched, idx) in matcheds">
        <template v-if="!matched.path"></template>
        <span
          :key="idx + '_'"
          v-else-if="!matched.meta.isOpen"
          @click="changeTab(matched, idx)"
        >
          <span v-html="$t(matched.name + '.title')"></span>
        </span>
        <el-link
          :key="'_' + idx"
          v-else-if="matched.path"
          :class="{ last: matcheds.length - 1 === idx }"
          :underline="false"
          @click="changeTab(matched, idx)"
        >
      <span v-html="$t(matched.name + '.title')"></span>
        </el-link>
        <!-- <el-link
        :key="idx + ','"
        class="link"
        :underline="false"
        v-else-if="matched.name != 'layout'"
        to="/"
      >
        首页
      </el-link> -->
        <span
          :key="idx + '.'"
          v-if="matcheds.length - 1 != idx && matched.name != 'layout'"
          class="separator"
          >/</span
        >
      </template>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    matcheds: {
      type: Array,
      default: () => {
        return [];
      },
    },
  },
  data() {
    return {};
  },
  created() {},
  mounted() {
    console.log(this.matcheds);
  },
  methods: {
    changeTab(route, idx) {
      let boon = this.matcheds.length - 1 != idx;
      if (!boon) return;
      this.$router.push(route.path);
    },
  },
};
</script>
<style lang="scss" scoped>
.breadcrumb {
  padding-top: 20px;
  padding-bottom: 20px;
  border-top: 1px solid #e6e6e6;
  .breadcrumb-body {
    width: var(--page-width);
    margin: 0 auto;
  }
  .tips {
    float: left;
  }
  .link:not(.last) {
    display: inline-block;
    position: relative;
    font-weight: bold;
  }
  .last {
    cursor: default;
    &:hover {
      color: $--color-text-primary;
    }
  }
  .separator {
    margin-left: $--padding;
    margin-right: $--padding;
    position: relative;
    top: 2px;
  }
}
</style>
