import Vue from "vue"
import VueRouter from "vue-router"
import layout from "@/components/layout"

// import routerView from "@/pages/routerView"
import notFound from "@/pages/404.vue"
import home from "@/pages/home/index.vue"
import product from "@/pages/product/index.vue"
import productView from "@/pages/product/view.vue"
import productView1 from "@/pages/product/view2.vue"

import news from "@/pages/news/index.vue"
import newsview from "@/pages/news/view.vue"

import access from "@/pages/access/index.vue"
import contact from "@/pages/contact/index.vue"
import company from "@/pages/company/index.vue"

// 后台管理
import login from "@/pages/admin/login.vue"
import admin from "@/pages/admin/index.vue"
import user from "@/pages/admin/user/index.vue"
import userEdit from "@/pages/admin/user/edit.vue"

import newsHome from "@/pages/admin/news"
import newsEdit from "@/pages/admin/news/edit"
import productEdit from "@/pages/admin/product"
import productList from "@/pages/admin/product/index.vue"
import lists from "@/pages/admin/product/edit.vue"
import adminHome from "@/pages/admin/home/index.vue"

Vue.use(VueRouter)
const routes = [
  {
    path: "/",
    name: "layout",
    component: layout,
    children: [
      {
        path: "/",
        name: "home",
        component: home,
        meta: {
          title: "首页",
        },
      },
      {
        path: "/product",
        name: "product",
        component: product,
        meta: {
          title: "产品",
          isOpen: true,
        },
        children: [
          {
            path: "/product/view",
            name: "productView",
            component: productView1,
            meta: {
              isHide: true,
            },
          },
          // {
          //   path: "/product/1",
          //   name: "product1",
          //   component: productView,
          //   meta: {
          //     title: "Crystal BioPure® Luer Lock",
          //     subTitle: "预灌封注射器",
          //   },
          // },
          // {
          //   path: "/product/2",
          //   name: "product2",
          //   component: productView,
          //   meta: {
          //     title: "Crystal BioPure® Luer Lock",
          //     subTitle: "双螺纹预灌封注射器",
          //   },
          // },
          // {
          //   path: "/product/3",
          //   name: "product3",
          //   component: productView,
          //   meta: {
          //     title: "Crystal BioPure® Stack Needle",
          //     subTitle: "预灌封注射器",
          //   },
          // },

          {
            path: "/productView/:id",
            name: "productView1",
            component: productView,
            meta: {
              isHide: true,
              title: "动态产品",
            },
          },
        ],
      },
      {
        path: "/news",
        name: "news",
        component: news,
        meta: {
          title: "新闻",
        },
      },
      {
        path: "/news/view",
        name: "newsView",
        component: newsview,
        meta: {
          isHide: true,
          title: "新闻详情",
        },
      },
      {
        path: "/access",
        name: "access",
        component: access,
        meta: {
          title: "访问",
        },
      },
      {
        path: "/contact",
        name: "contact",
        component: contact,
        meta: {
          isOpen: true,
          title: "联系我们",
        },
      },
      {
        path: "/company",
        name: "company",
        component: company,
        meta: {
          title: "关于公司",
          isHide: true,
        },
        children: [
          {
            path: "/company/1",
            name: "company1",
            component: company,
            meta: {
              title: "公司简介",
            },
          },
          {
            path: "/company/2",
            name: "company2",
            component: company,
            meta: {
              title: "产品介绍",
            },
          },
          {
            path: "/company/3",
            name: "company3",
            component: company,
            meta: {
              title: "服务介绍",
            },
          },
        ],
      },

      {
        path: "/admin/login",
        name: "login",
        component: login,
        meta: {
          isHide: true,
          title: "登录",
        },
      }
    ],
  },

  {
    path: "/admin",
    name: "admin",
    redirect: "/admin/login",
    component: admin,
    meta: {
      isHide: true,
    },

    children: [
      {
        path: "adminHome",
        name: "adminHome",
        component: adminHome,
        meta: {
          title: "首页管理",
        },
      },
      // {
      //   path: "banner",
      //   name: "banner",
      //   component: banner,
      //   meta: {
      //     title: "banner管理",
      //   },
      // },
      {
        path: "newsHome",
        name: "newsHome",
        component: newsHome,
        meta: {
          title: "新闻管理",
        },
      },
      {
        path: "newsEdit",
        name: "newsEdit",
        component: newsEdit,
        meta: {
          isHide: true,
          title: "新闻管理",
        },
      },
      {
        path: "productList",
        name: "productList",
        component: productList,
        meta: {
          title: "产品管理",
        },
      }, {
        path: "lists",
        name: "lists",
        component: lists,
        meta: {
          isHide: true,
          title: "产品管理",
        },
      },

      {
        path: "productEdit",
        name: "productEdit",
        component: productEdit,
        meta: {
          title: "产品描述",
        },
      },
      {
        path: "user",
        name: "user",
        component: user,
        meta: {
          title: "用户列表",
        }
      },
      {
        path: "userEdit",
        name: "userEdit",
        component: userEdit,
        meta: {
          isHide: true,
          title: "用户编辑",
        },
      },
    ],
  },
  {
    path: "*",
    name: "layout",
    component: layout,
    children: [
      {
        path: "*",
        name: "404",
        component: notFound,
        meta: {
          isHide: true,
          title: "暂无页面",
          icon: "el-icon-notebook-2",
        },
      },
    ],
  },
]
const router = new VueRouter({
  routes,
  scrollBehavior() {
    return { x: 0, y: 0 }
  },
})
export default router
