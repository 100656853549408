<template>
  <base-dialog
    title="设置"
    ref="dialog"
    :modal="false"
    width="80%"
    :footer="true"
    :form="form"
    :rules="rules"
    :formProps="formProps"
    formItemWidth="100%"
    labelWidth="130px"
  >
    <template slot="img" slot-scope="{prop}">
      <el-upload
        class="avatar-uploader"
        :action="action"
        :show-file-list="false"
        :on-success="(data)=>{handleAvatarSuccess(data,prop)}"
        :before-upload="beforeAvatarUpload"
      >
        <img v-if="form[prop]" :src="path + form[prop]" class="avatar" />
        <i v-else class="el-icon-plus avatar-uploader-icon"></i>
      </el-upload>
      <b >图片最佳尺寸：492px * 121px</b>
    </template>
    <div slot="headerTools"></div>
    <editor v-model="form[prop]" slot="editor" slot-scope="{ prop }"></editor>
    <div slot="footer">
      <el-button type="primary" @click="save">保存</el-button>
      <el-button @click="$close()">取消</el-button>
    </div>
  </base-dialog>
</template>

<script>
import editor from "@/components/editor";
const path = config.BASEURL;
export default {
  components: { editor },
  data() {
    return {
      path,
      action: path + "/main/uploads",
      form: {},
      rules: {
        address: [{ required: true, message: "请输入地址", trigger: "blur" }],
        phone: [{ required: true, message: "请输入联系方式", trigger: "blur" }],
        email: [{ required: true, message: "请输入邮箱", trigger: "blur" }],
      },
      formProps: {
        logo1: { label: "白色 logo",width:'33.333%', slotName: "img" },
        logo2: { label: "暗色 logo", width:'33.333%',slotName: "img" },
        logo3: { label: "其他 logo", width:'33.333%',slotName: "img" },
        address: {
          label: "地址",
          type: "input",
          attr: {
            placeholder: "请输入地址",
          },
        },
        phone: {
          label: "联系方式",
          type: "input",
          attr: {
            placeholder: "请输入联系方式",
          },
        },

        email: {
          label: "邮箱",
          type: "input",
          attr: {
            placeholder: "请输入邮箱",
          },
        },

        address_en: {
          label: "地址(英文)",
          type: "input",
          attr: {
            placeholder: "请输入地址",
          },
        },
        accessDesc: {
          label: "关于我们",
          slotName: "editor",
          attr: {
            rows: 8,
            type: "textarea",
            placeholder: "请输入关于我们",
          },
        },
        accessDesc_en: {
          label: "关于我们(英文)",
          slotName: "editor",
          attr: {
            rows: 8,
            type: "textarea",
            placeholder: "请输入关于我们",
          },
        },
      },
    };
  },
  methods: {
    $open() {
      this.$refs.dialog.$open();
      this.getView();
    },
    $close() {
      this.$refs.dialog.$close();
      this.form = {};
      this.$refs.form.clearValidate();
    },
    getView() {
      this.form = {};
      this.$api.getCompany().then((res) => {
        if (res.data) {
          this.form = res.data;
        } // console.log(res)
      });
    },
    handleAvatarSuccess(data,prop) {
      this.$message.success("上传成功");
      console.log(data,prop)
      this.form[prop] = data.data.path;
    },
    beforeAvatarUpload(file) {
      const isJPG = file.type === "image/jpeg" || file.type === "image/png";
      if (!isJPG) {
        this.$message.error("上传头像图片只能是 JPG 或 PNG 格式!");
      }
      return isJPG;
    },
    save() {
      // this.$refs.dialog.$close();
      this.$api.saveCompany(this.form).then(() => {
        this.$message.success("保存成功");
        this.$close();
      });
    },
  },
};
</script>

<style></style>
