<template>
  <layout>
    <el-container class="admin-wrap">
      <el-aside v-show="user">
        <base-aside
          mode="vertical"
          :active="$route.name"
          :routers="routerList"
        ></base-aside>
        <div class="tools">
          <el-link
            type="primary"
            icon="el-icon-office-building"
            @click="openInfo"
            >公司信息</el-link
          >
          <el-link
            style="margin-left: 10px"
            type="primary"
            icon="el-icon-s-tools"
            @click="openSetting"
            >设置</el-link
          >
        </div>
      </el-aside>
      <el-main>
        <router-view :key="$route.path"></router-view>
      </el-main>
    </el-container>
    <setting ref="setting"></setting>
    <info ref="info"></info>
  </layout>
</template>

<script>
import layout from "@/components/layout";
import baseAside from "@/components/layout/aside";
import setting from "./setting";
import info from "./info";
export default {
  components: { layout, baseAside, setting, info },
  data() {
    return {
      user: null,
      routerList: {},
    };
  },
  mounted() {
    this.user = sessionStorage.getItem("user");
    let router = this.$router;
    let options = router.options.routes[1];
    this.routerList = { url: options.path, menus: options.children };
  },
  methods: {
    openSetting() {
      this.$refs.setting.$open();
    },
    openInfo() {
      this.$refs.info.$open();
    },
  },
};
</script>

<style lang="scss">
.admin-wrap {
  padding-left: 10px;
  padding-right: 10px;
  .tools {
    padding: 10px;
    border-top: 1px solid $--border-color;
    text-align: right;
  }

  .el-menu {
    .el-menu-item .title {
      position: relative;
      &::after {
        content: "";
        position: absolute;
        left: -20px;
        top: 50%;
        width: 6px;
        height: 48%;
        border-radius: 4px;
        transform: translateY(-50%);
      }
    }
    .el-menu-item.is-active .title::after {
      background: $--color-primary;
    }
  }
}
</style>
