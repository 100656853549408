export function formatDate(date, format) {
  if (typeof date == "string") {
    date = new Date(date)
  }
  let now = new Date()
  let diff = now.getTime() - date.getTime()
  if (!format) {
    if (diff < 60 * 1000) {
      return "刚刚"
    } else if (diff < 60 * 60 * 1000) {
      return Math.floor(diff / (60 * 1000)) + "分钟前"
    } else if (diff < 24 * 60 * 60 * 1000) {
      return Math.floor(diff / (60 * 60 * 1000)) + "小时前"
    }
  }
  let year = date.getFullYear()
  let month = String(date.getMonth() + 1).padStart(2, "0") // 月份是从0开始的
  let day = String(date.getDate()).padStart(2, "0")
  let hours = String(date.getHours()).padStart(2, '0');
  let minutes = String(date.getMinutes()).padStart(2, '0');
  let seconds = String(date.getSeconds()).padStart(2, '0');

  return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`
}
