<template>
  <el-header class="header">
    <div class="header-left">
      <slot name="logo">
        <div class="logo">
          <!-- <i class="el-icon-moon-night img"></i> -->
          <div class="title">{{ title }}</div>
        </div>
      </slot>
    </div>

    <div class="header-right">
      <slot name="header-right">
        <header-tools class="header-right"></header-tools>
      </slot>
    </div>
  </el-header>
</template>

<script>
import headerTools from "./header-tools"
export default {
  name: "header-layout",
  props: {
    title: String,
  },
  components: { headerTools },
}
</script>

<style lang="scss" scoped>
.header {
  overflow: hidden;
  height: 120px !important;
  line-height: 120px;
  color: $--color-theme;
  display: flex;
  justify-content: space-between;
  &-left {
    float: left;
    text-align: right;
  }
  &-right {
    float: right;
  }
}
.logo {
  display: flex;
  align-items: center;
  object-fit: contain;
  .img {
    font-size: var(--font-size);
    margin-right: $--margin;
  }
  .title {
    display: inline-block;
    font-weight: bold;
    font-size: calc(var(--font-size) + 6px) ;
  }
}
</style>
