<template>
  <base-table class="user" :tableHeader="tableHeader" :responses="tableData">
    <template slot="btns" slot-scope="{ selection }">
      <el-button
        type="warning"
        :disabled="loading || !selection || selection.length == 0"
        @click="exportData(selection)"
        >选中导出</el-button
      >
    </template>
    <div slot="opt" class="row-btns" slot-scope="{ row }">
      <el-link
        type="primary"
        @click="$router.push({ name: 'userEdit', query: { id: row.id } })"
      >
        回复
      </el-link>
    </div>
  </base-table>
</template>

<script>
import { zh } from "@/i18n/zh.js";
import { en } from "@/i18n/en.js";
import { formatDate } from "@/utils/index.js";
export default {
  data() {
    const _this = this;
    return {
      loading: false,
      typeOptins: [
        {
          name: en.product1.subTitle,
          value: zh.product1.subTitle,
        },
        {
          name: en.product2.subTitle,
          value: zh.product2.subTitle,
        },
        {
          name: en.product3.subTitle,
          value: zh.product3.subTitle,
        },
        {
          name: "Other Products",
          value: "其他产品",
        },
      ],
      tableHeader: [
        {
          label: "#",
          type: "selection",
        },
        {
          label: zh.contact.companyname,
          prop: "companyname",
          width: 150,
        },
        {
          label: zh.contact.surname + zh.contact.name,
          width: 150,
          prop: "name",
          render(h, row) {
            return h("span", row.surname + " " + row.name);
          },
        },
        { label: zh.contact.phone, prop: "phone", width: 150 },
        {
          label: "创建时间",
          prop: "create_time",
          width: 150,
          render(h, row) {
            return h(
              "span",
              (row.create_time && formatDate(row.create_time)) || "--"
            );
          },
        },
        { label: zh.contact.country, prop: "country", width: 150 },
        { label: zh.contact.address1, prop: "address1", width: 150 },
        { label: zh.contact.address2, prop: "address2", width: 150 },
        { label: zh.contact.city, prop: "city", width: 150 },
        { label: zh.contact.state, prop: "state", width: 150 },
        { label: zh.contact.zip, prop: "zip", width: 150 },
        { label: zh.contact.email, prop: "email", width: 150 },

        {
          label: zh.contact.type,
          prop: "type",
          width: 150,
          render(h, row) {
            if (!row.type) return "--";
            let opt = _this.typeOptins.find(
              (item) => item.value == row.type || item.name == row.type
            );
            return h("span", _this.$i18n.locale == "zh" ? opt.value : opt.name);
          },
        },

        {
          label: zh.contact.content,
          prop: "content",
          width: 300,
          fixed: "right",
        },
        { label: zh.contact.notes, prop: "notes", width: 150 },
        {
          label: "状态",
          prop: "status",
          fixed: "right",
          render(h, row) {
            let s = {
              1: "未查看",
              2: "已查看",
              3: "已回复",
            };
            return h("span", s[row.status] || row.status || "--");
          },
        },
        {
          label: "操作",
          fixed: "right",
          slotName: "opt",
        },
      ],
      tableData: [],
    };
  },
  created() {
    this.getList();
  },
  methods: {
    exportData(data) {
      this.loading = true;
      let p = [];
      data.forEach((val) => {
        let obj = {};
        for (let key in val) {
          let k = this.tableHeader.find((item) => item.prop == key);
          if (!k) {
            k = key;
          } else {
            k = k.label;
          }
          let value = val[key];
          if (key == "surname" || key == "name") {
            k = "姓名";
            value = val.surname + val.name;
          }
          if (key == "create_time") k = "创建时间";
          if (key != "agree" && key != "status" && key != "privacyPolicy") {
            obj[k] = value;
          }
        }
        p.push(obj);
      });
      this.$api.exportData({ data: p }).then((res) => {
        this.loading = false;
        let b = new Blob([res.data], {
          type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
        });
        let url = window.URL.createObjectURL(b);
        let link = document.createElement("a");
        link.style.display = "none";
        link.href = url;
        link.setAttribute("download", "登记用户信息.xlsx");
        document.body.appendChild(link);
        link.click();
      });
    },
    getList() {
      this.$api.getUserList().then((res) => {
        this.tableData = res.data;
      });
    },
  },
};
</script>

<style lang="scss">
.el-pagination {
  margin-top: 10px;
  .el-select,
  .el-input,
  .el-select .el-input .el-input__inner,
  .el-pagination__editor.el-input .el-input__inner {
    height: 100% !important;
    font-size: 16px;
  }
  .el-select .el-input .el-input__suffix {
    display: none;
  }
}
.user {
  .el-checkbox__inner::after {
    top: 50%;
    left: 50%;
    transform: rotate(45deg) scaleY(1) translate(-50%, -50%) !important;
    margin-left: -2px;
    margin-top: -2px;
  }
}
</style>
