<template>
  <div class="tools">
    <el-dropdown v-if="user.userName">
      <span class="el-dropdown-link">
        <!-- <img src="@/assets/img/head-photo.jpeg" class="avatar" /> -->

        <el-link>{{ user.userName }}<i class="el-icon-arrow-down"></i></el-link>
        <!-- <el-badge is-dot>
           <i class="el-icon-message-solid"></i>
        </el-badge> -->
      </span>
      <el-dropdown-menu slot="dropdown">
        <el-dropdown-item>
          <el-link :underline="false" @click="openDialog">重置密码</el-link>
        </el-dropdown-item>
        <el-dropdown-item divided>
          <el-link :underline="false" icon="el-icon-setting" @click="signOut"
            >退出</el-link
          >
        </el-dropdown-item>
      </el-dropdown-menu>
    </el-dropdown>
    <!-- <el-link icon="el-icon-orange"></el-link> -->
  </div>
</template>

<script>
export default {
  name: "header-tools",
  data() {
    return {
      user: {},
      form: {},
      rules: {
        password: [
          {
            required: true,
            message: "请输入密码",
            trigger: ["blur", "change"],
          },
        ],
      },
      formProps: {
        userName: {
          type: "input",
          label: "当前用户",
          attr: {
            disabled: true,
          },
        },
        password: {
          type: "input",
          label: "修改密码",
          attr: {
            type: "password",
          },
        },
      },
    }
  },
  created() {
    let user = sessionStorage.getItem("user")
    if (user) {
      this.user = JSON.parse(user)
      this.form = this.user
    }
  },
  methods: {
    signOut() {
      sessionStorage.clear()
      this.$router.push("./login")
    },
    openDialog() {
      this.$refs.dialog.$open()
    },
    reset() {
      const { userId, password } = this.form
      this.$apiPc.reset({ userId, password }).then(() => {
        this.$message.success("修改成功，请重新登录")
        this.signOut()
      })
    },
  },
}
</script>

<style lang="scss" scoped>
.tools {
  .icon {
  }

  .el-dropdown {
    color: $--color-reverse;
    position: relative;
    cursor: pointer;

    .avatar {
      $width: 50px;
      display: inline-block;
      background-color: $--img-bg;
      width: $width;
      height: $width;
      border-radius: 100%;
      overflow: hidden;
      position: absolute;
      // left: calc($width + $--margin / 2);
      top: 50%;
      // margin-top: calc($width / 2);
    }
  }

  .el-link {
    color: $--color-reverse;
    font-size: var(--font-size);

    &:hover {
      color: mix($--color-primary, $--color-theme, 30%);
    }

    & + .el-link {
      // margin-left: calc($--margin / 2);
    }
  }
}
</style>
