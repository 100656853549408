import router from "./router.js"
/* 白名单 */
router.beforeEach((to, from, next) => {
    let user = sessionStorage.getItem('user');
    // console.log(to.path)
    if (to.path.indexOf('admin') > -1 && !user && to.path.indexOf('login') == -1) {
        next('/admin/login') 
    }else if(to.path == 'login' || to.path.indexOf('login') > -1 && user){
        next('/admin/adminHome') 
    }else{ 
        next()
    }
   
 })

export default router
