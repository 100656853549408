<template>
<router-view :key="$route.path"></router-view>
</template>

<script>
export default {
  props:{},
  data() {
    return {}
  },
  created() {},
  mounted() {},
  methods: {}
}
</script>
<style lang="scss" scoped>
</style>