<template>
  <div class="product">
    <div class="msg-list">
      <h3 class="list-title">{{ $t("product.title") }}</h3>
      <div class="list-item" v-for="(msg, idx) in proList" :key="idx + '/'">
        <el-image
          class="img"
          :src="require('@/assets/img/' + msg.img)"
        ></el-image>
        <el-row class="item-main" :gutter="20">
          <el-col :span="10">
            <b>A点中文</b>
            <span>建议：标题与内容一行控制在16个中文字符以内。</span>
            <el-input v-model="msg.titleA" placeholder="标题"></el-input>
            <editor v-model="msg.contentA" placeholder="内容"></editor>
          </el-col>
          <el-col :span="10">
            <b>B点中文</b>
            <span>建议：标题与内容一行控制在16个中文字符以内。</span>
            <el-input v-model="msg.titleB" placeholder="标题"></el-input>
            
            <editor v-model="msg.contentB" placeholder="内容"></editor>
          </el-col>
          <el-col :span="4">
            <el-button type="primary" @click="save(msg, idx)">保存</el-button>
          </el-col>
          <el-col :span="10">
            <b>A点英文</b>
            <span>建议：标题与内容一行控制在60个英文字母（包含空格与标点符号）以内。</span>
            <el-input v-model="msg.titleA_en" placeholder="标题"></el-input>
            <editor v-model="msg.contentA_en" placeholder="内容"></editor>
          </el-col>
          <el-col :span="10">
            <b>B点英文</b>
            <span>建议：标题与内容一行控制在60个英文字母（包含空格与标点符号）以内。</span>
            <el-input v-model="msg.titleB_en" placeholder="标题"></el-input>
            <editor v-model="msg.contentB_en" placeholder="内容"></editor>
          </el-col>
        </el-row>
      </div>
    </div>
  </div>
</template>

<script>
import editor from "@/components/editor";
export default {
  components: { editor },
  data() {
    return {
      form: {},
      proList: [
        {
          img: "product2.png",
          title: "product1.title",
          subTitle: "product1.subTitle",
        },
        {
          img: "product3.png",
          title: "product2.title",
          subTitle: "product2.subTitle",
        },
        {
          img: "product4.png",
          title: "product3.title",
          subTitle: "product3.subTitle",
        },
      ],
    };
  },
  created() {
    this.$api.productList().then((res) => {
      //   console.log(res.data)
      this.proList.forEach((val, idx) => {
        let opt = res.data.find((item) => val.title.indexOf(item.name) > -1);
        if (opt) {
          this.$set(this.proList, idx, {
            ...val,
            ...opt,
          });
        }
      });
      //   this.$forceUpdate()
      //   console.log(this.proList)
    });
  },
  methods: {
    save(msg, idx) {
      const {
        id,
        titleA,
        contentA,
        titleB,
        contentB,
        titleA_en,
        contentA_en,
        titleB_en,
        contentB_en,
      } = msg;
      this.$api
        .productSave({
          name: "product" + (idx + 1),
          titleA,
          contentA,
          titleB,
          contentB,
          id,
          titleA_en,
          contentA_en,
          titleB_en,
          contentB_en,
        })
        .then(() => {
          this.$message.success("保存成功");
        });
      //   console.log(this.form, id)
    },
  },
};
</script>
<style lang="scss" scoped>
.msg-list {
  padding-bottom: 120px;
  .list-item {
    flex-direction: column;
    align-items: center;
  }
  .item-main {
    margin-top: 20px;
    width: 100%;
    b {
      display: block;
      margin-top: 20px;
      margin-bottom: 20px;
    }
    .el-input {
      margin-bottom: 20px;
    }
  }
  .img {
    width: 360px;
    min-width: 360px;
    height: 220px;
    display: block;
  }
}
</style>
