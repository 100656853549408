<template>
  <div class="not-found">
    <img src="@/assets/img/404.svg" />
    <br />
    <el-button type="primary" plain @click="$router.push('/')">{{ $t('return') }}</el-button>
  </div>
</template>

<script>
export default {
  name: "no-found",
}
</script>

<style lang="scss">
.not-found {
  display: flex;
  flex-direction: column;
  align-items: center;

  img {
    width: 400px;
    height: 400px;
  }
  
}
</style>
