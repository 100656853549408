<template>
  <div>
    <el-page-header
      @back="goBack"
      :content="(query.type == 'add' ? '新增' : '编辑') + '新闻'"
    >
    </el-page-header>
    <div class="news-edit">
      <el-form ref="form" :model="form" :rules="rules">
        <el-form-item label="新闻封面图" prop="img">
          <el-upload
            class="avatar-uploader"
            :action="action"
            :show-file-list="false"
            :on-success="handleAvatarSuccess"
            :before-upload="beforeAvatarUpload"
          >
            <img v-if="form.img" :src="path + form.img" class="avatar" />
            <i v-else class="el-icon-plus avatar-uploader-icon"></i>
          </el-upload>
        </el-form-item>
        <el-form-item label="新闻类型" prop="type">
          <el-select v-model="form.type" placeholder="请选择新闻类型">
            <el-option
              v-for="item in typeOptions"
              :key="item.value"
              :label="$i18n.locale == 'zh' ? item.value : item.name"
              :value="item.value"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="标题" prop="title">
          <el-input v-model="form.title"></el-input>
        </el-form-item>
        <el-form-item label="新闻内容" prop="content">
          <editor v-model="form.content" />
        </el-form-item>

        <el-form-item label="标题(英文)" prop="content">
          <el-input v-model="form.title_en"></el-input>
        </el-form-item>
        <el-form-item label="新闻内容（英文）" prop="content">
          <editor v-model="form.content_en" />
        </el-form-item>
      </el-form>
      <div class="flex flex-center">
        <!-- <el-button type="primary" @click="$router.push('/news')">返回</el-button> -->
        <el-button type="primary" @click="submit">保存</el-button>
      </div>
    </div>
  </div>
</template>

<script>
import editor from "@/components/editor"
const path = config.BASEURL
export default {
  components: {
    editor,
  },
  data() {
    return {
      query: {},
      action: path + "/main/uploads",
      path,
      form: {
        img: "",
      },
      typeOptions: [
        { name: "International News", value: "国际新闻" },
        { name: "Company News", value: "公司新闻" },
      ],
      rules: {
        img: [{ required: true, message: "请上传图片", trigger: "blur" }],
        title: [{ required: true, message: "请输入标题", trigger: "blur" }],
        content: [{ required: true, message: "请输入内容", trigger: "blur" }],
        title_en: [
          { required: true, message: "请输入英文标题", trigger: "blur" },
        ],
        content_en: [
          { required: true, message: "请输入英文内容", trigger: "blur" },
        ],
      },
    }
  },
  created() {
    
    this.user = sessionStorage.getItem("user")
    if (this.user) {
      this.user = JSON.parse(this.user)
    }
    this.query = this.$route.query
    if (this.query.id) {
      this.$api.getNewsById({ id: this.query.id }).then((res) => {
        this.form = res.data
      })
    }
  },
  methods: {
    goBack() {
      this.$router.replace("/admin/newsHome")
    },
    handleAvatarSuccess(data) {
      this.form.img = data.data.path
    },
    beforeAvatarUpload(file) {
      const isJPG = file.type === "image/jpeg" || file.type === "image/png"
      if (!isJPG) {
        this.$message.error("上传头像图片只能是 JPG 或 PNG 格式!")
      }
      return isJPG
    },
    submit() {
      if (this.form.img == "") {
        this.$message.error("请上传图片")
        return false
      }
      this.$refs.form.validate((valid) => {
        if (valid) {
          this.form.author = this.user.username
          this.$api.saveNews(this.form).then(() => {
            this.$message.success(this.$t("success"))
            this.$refs.form.resetFields()
            this.goBack()
          })
        }
      })
    },
  },
}
</script>

<style lang="scss">
.news-edit {
  .el-form .el-form-item__label {
    float: none;
  }

  .el-input {
    height: 56px;
    line-height: 56px;
    .el-input__inner {
      height: 56px !important;
      line-height: 56px  !important;
      font-size: 16px;
    }
    .el-input__suffix{
      display: none;
    }
  }
}
</style>
