export const zh = {
  lang: "ENG",
  title: "巴特弗莱医药包装",
  footerTitle:"南京巴特弗莱医药包装材料有限责任公司",
  info: "引领高分子预灌封系统的技术创新<br/>无硅油，更简洁，更好",
  bannerBtn: "公司实力",
  address: "江苏省南京市溧水经济开发区水保路1号9a幢",
  addr: "地址",
  tel: "电话",
  email: "邮箱",
  required: "必填",
  click: "点击直达",
  inquiry: "咨询表格",
  success: "成功",
  return: "返回首页",
  more: "更多",
  other: "其他",
  home: {
    title: "首页",
    product: "产品",
    company: "公司",
    productDesc:
      "采用最先进的模块化设计生产技术，出色的产品性能可为您控制研发成本和周期，提高药品稳定性和安全性。",
    companytDesc:
      "由南京巴特弗莱医药包装材料有限责任公司为高端注射剂独家设计、量身打造的一系列高性能、无硅油给药系统。",
    learnMore: "了解更多",
    readMore: "阅读更多",
    other1: "持续可靠的质量",
    otherDesc1: "在百级洁净环境下生产，遵循GMP和ISO15378要求",
    other2: "优雅的产品设计",
    otherDesc2: "助力企业从硼硅玻璃系统升级为下一代高性能材料系统",
    other3: "联系我们",
    otherDesc3: "如果您对巴特弗莱有任何疑问，请在此处联系我们",
    msg: "新闻消息",
    newsMore: "新闻消息",
  },
  product: {
    title: "产品",
    subTitle: "产品",
    desc: "采用最先进的模块化设计生产技术，出色的产品性能可为您控制研发成本和周期，提高药品稳定性和安全性。",
    refineOn: "精益求精",
    subTitle1: "产品特性",
    subTitle2: "原料特性",
    subTitle3: "原料性能",
  },
  productView: {
    title: "产品介绍",
    subTitle: "产品介绍",
    module1Desc:'100%视觉检测',
    module2Desc:'在百级送风(Grade A Air Supply)环境下生产',
  },
  productView1: {
    title: "产品介绍",
    subTitle: "产品介绍",

   },
  product1: {
    title: "Crystal BioPure<sup>®</sup> Luer Lock",
    title1: "Crystal BioPure<sup>®</sup> Luer Lock",
    subTitle: "鲁尔锁预灌封注射器",
    desc: "超低温冻存药品，眼科注射液，复杂制剂(多囊脂质体、LNP、纳米晶等)，配套冻干粉针的溶剂",
    tags: "可选规格：0.5mL,1mL long，3mL_材 质：COC或COP_润滑方式：无硅油系统",
  },
  product2: {
    title: "Crystal BioPure<sup>®</sup> Luer Lock",
    title1: "Crystal BioPure<sup>®</sup> Luer Lock",
    subTitle: "鲁尔锁双螺纹预灌封注射器",
    desc: "超高粘度流体在流经鲁尔锥时受到双螺纹流道引导，流动路径更长注射针部位压力得到缓解，提供更好的用户体验",
    tags: "可选规格：1mL extremely long_材 质：COC_润滑方式：无硅油系统",
  },
  product3: {
    title: "Crystal BioPure<sup>®</sup> Stack Needle",
    title1: "Crystal BioPure<sup>®</sup> Stack Needle",
    subTitle: "插针预灌封注射器",
    desc: "注射针在注射器注塑成型时，放入注塑模具熔融的COP树脂包裹着注射针完成塑化过程，无需钨针制造流道，无需UV胶水",
    tags: "注射器规格：1mL long，1mL standard，2.25mL_材 质：COP_润滑方式：无硅油系统_无UV胶水",

  },
  product4: {
    title: "Crystal BioPure<sup>®</sup> bespoke",
    title1: "Crystal BioPure<sup>®</sup> bespoke",
    subTitle: "定制化系统",
    desc: "通过采用下一代高性能材料，具备无与伦比的设计自由度，可满足您的定制化需求",
    tags: "无硅油系统_无UV固化胶",

  },
  news: {
    title: "新闻",
    click: "点击查看",
    view: "浏览",
  },
  newsView: {
    title: "新闻详情",
  },
  newsHome: {
    title: "新闻维护",
  },
  newsEdit: {
    title: "新闻编辑",
  },
  access: {
    title: "关于我们",
    subTitle: "关于我们",
    desc: "<p>南京巴特弗莱医药包装材料有限责任公司(以 下 简 称“Butterfly”)成立于2022年，作为中国第一家量产无硅油高分子预灌封注射器的公司，Butterfly按照药品、药包材行业最新GMP、ISO9001、IS015378、IS013485等国内和国际法规、标准和指南要求，已建设300+平米ISO7级(GradeC)背景洁净车间和ISO5级(Grade A)核心操作洁净区域，为客户提供最高水准的生产环境。同时采用半导体行业微粒控制手段降低异物附着的风险。产品微粒水平、初始污染菌水平高度可控，可持续为客户提供高品质的高分子无硅油预灌封注射器。</p>",
    company: "总公司",
  },
  contact: {
    title: "联系我们",
    desc: "如果您对巴特弗莱有任何疑问，请在此处联系我们。",
    steps: "输入您的询问详情,确认内容,询价已成功发送",

    formTitle: "输入您的询问详情",

    input: "请输入",
    select: "请选择",
    // 表单
    companyname: "公司名称",
    companyVerify: "最多30个字符",
    surname: "姓",
    name: "名",
    nameVerify: "最多10个字符",
    country: "国家/地区",
    countryPlaceholder: "选择国家",
    address1: "地址1",
    address2: "地址2",
    city: "城市",
    state: "省份/州",
    zip: "邮政编码",
    phone: "电话",
    email: "邮箱",
    notes: "备注/回复",
    type: "咨询类别",
    typePlaceholder: "选择一个类别",
    agree: "接受我们的隐私政策",
    content: "您的询问",
    contentPlaceholder: "你的疑问",
    contentVerify: "请输入准确的信息以便我们提供适当的答复",
    privacyPolicy: "隐私政策",
    privacyPolicyDesc:
      "通过此表单收集的信息将用于回复有关公司产品、服务等的咨询。回复咨询后，我们可能会根据以下使用目的处理这些信息。\n" +
      "收集的信息：\n" +
      "公司名称、姓名、国家、邮政编码、州、城市、门牌号、建筑物名称、电话号码、电子邮件地址、查询类型和查询详情。​\n​" +
      "注意：请输入准确的信息，以便我们提供适当的回复。如果您希望我们通过电话联系您，请输入您的电话号码。\n​" +
      "使用目的：\n" +
      "通过确认国家和公司地址，对咨询做出适当的回应\n" +
      "通过电子邮件、邮寄、电话、视频会议、咨询等方式提供信息，以响应有关公司产品的问询和文件请求。\n" +
      "通过合同、公司提案、确认订单的电子邮件、邮寄、电话、视频会议、咨询等方式从事销售活动、营销及相关业务\n" +
      "通过电子邮件、邮寄、电话、视频会议、咨询等方式进行问卷调查，了解产品开发和质量改进措施\n" +
      "合同管理、订单处理、交付产品、提供支持、开具发票\n" +
      "通过电子邮件、邮寄、电话、视频会议、咨询或其他方式回应媒体问询或采访。\n" +
      "通过电子邮件、邮寄、电话、视频会议或其他方式提供信息，以回应有关招聘的问询。\n" +
      "通过电子邮件、邮寄、电话或其他方式回应其他问询以及根据问询或请求进行的业务改进。\n" +
      "如果根据法律要求或法院命令要求披露\n" +
      "关于本公司的个人信息咨询窗口以及本公司对个人信息除此处所述使用目的以外的其他用途的处理，请阅读隐私声明（关于个人信息处理的书面声明）。",
    privacyPolicyDesc1:
      "要使用此表格，我们需要您同意我们处理您的个人信息。如果您在阅读此表格后 选择同意，请在下方勾选后填写此表格。",
    submit: "确认内容",
    edit: "修改",
    send: "发送",
    return: "返回首页",
    successTitle: "询价已成功发送",
    successDesc: "询价已成功发送，请保持邮件、电话畅通，等待客服与您联系。",
  },
  company: {
    title: "关于公司",
    subTitle: "公司",
  },
  company1: {
    title: "公司简介",
  },
  company2: {
    title: "产品介绍",
  },
  company3: {
    title: "服务介绍",
  },
  admin: {
    title: "后台管理",
  },
  login: {
    title: "登录",
  },
  user: {
    title: "用户信息",
  },
  banner: {
    title: "banner管理",
  },
  productEdit: {
    title: "产品描述",
  },
  productList: {
    title: "产品管理",
  },
  adminHome: {
    title: "首页管理",
  },
  lists: {
    title: "产品管理",
  },
}
