import Vue from "vue"
import App from "./App.vue"
import element from "element-ui"
import base from "base-commons"
import * as echarts from 'echarts';
import "./assets/css/style.scss"
import router from "./router"
import http from "./utils/http.js"
import api from "./utils/api.js"
import { i18n } from "./i18n/index.js"
import components from "./components/index"
import "amfe-flexible"

Vue.config.productionTip = false
Vue.prototype.$http = http
Vue.prototype.$api = api
Vue.prototype.$echarts = echarts

for (let key in components) {
  Vue.component(key, components[key])
}
Vue.use(element, { size: "medium" })
Vue.use(base)

new Vue({
  i18n,
  router,
  render: (h) => h(App),
}).$mount("#app")
