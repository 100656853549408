<template>
  <el-menu
    :default-active="active"
    :collapse="isCollapse"
    :router="router"
    @open="handleOpen"
    @close="handleClose"
    @select="select"
    :mode="mode"
    :default-openeds="defaultOpeneds"
  >
    <template v-for="(router, idx) in routerList">
      <sub-menu
        :isOpenParent="isOpenParent"
        :index="router.path"
        :router="router"
        :key="idx"
        @select="select"
      ></sub-menu>
    </template>
  </el-menu>
</template>

<script>
import subMenu from "./submenu";
export default {
  name: "aside-layout",
  props: {
    router: {
      type: Boolean,
      default: true,
    },
    mode: {
      type: String,
      default: "horizontal",
    },
    defaultOpeneds: {
      type: Array,
      default: () => {
        return [];
      },
    },
    /* 是否打开父级 */
    isOpenParent: {
      type: Boolean,
      default: true,
    },
    active: {
      type: String,
      default: "/",
    },
    routers: {
      type: Object,
      default: () => {
        return {};
      },
    },
  },
  components: {
    subMenu,
  },
  data() {
    return {
      isCollapse: false,
      routerList: [],
    };
  },
  computed: {},
  watch: {
    routers(value) {
      if (!value) return;
      const { menus } = value;
      if (menus && menus.length > 0) {
        this.routerList = menus;
      }
    },
  },
  mounted() {
    this.init();
  },
  methods: {
    init() {},
    select(path,b,c) {
      console.log(path,b,c);
      // this.$router.push(path)
      this.$emit("select", path);
    },
    handleOpen(path) {
      if (this.isOpenParent) this.openParent(path);
    },
    handleClose(path) {
      if (this.isOpenParent) this.openParent(path);
    },
    openParent(e) {
      this.$router.push(e);
    },
  },
};
</script>

<style lang="scss" scoped>
.el-menu {
  border: none;
}
</style>
