<template>
  <div>
    <el-page-header
      @back="goBack"
      :content="(query.type == 'add' ? '新增' : '编辑') + ''"
    >
    </el-page-header>
    <div class="news-edit">
      <el-tabs v-model="activeName" type="card">
        <el-tab-pane label="基础信息" name="1">
          <base-form
            ref="form"
            formItemWidth="100%"
            labelWidth="130px"
            :form="form"
            :rules="rules"
            :formProps="formProps"
          >
            <div class="flex" slot="link">
              <base-aside
                style="width: 300px; margin-right: 10px；"
                :router="false"
                ref="aside"
                mode="vertical"
                :isOpenParent="false"
                :active="$route.name"
                :routers="routerList"
                @select="selectLink"
              ></base-aside>
              <div style="font-weight: bold">
                已选择：
                {{ form.link }}
              </div>
            </div>

            <template slot="img">
              <el-upload
                class="avatar-uploader"
                :action="action"
                :show-file-list="false"
                :on-success="handleAvatarSuccess"
                :before-upload="beforeAvatarUpload"
              >
                <img v-if="form.img" :src="path + form.img" class="avatar" />
                <i v-else class="el-icon-plus avatar-uploader-icon"></i>
              </el-upload>
              <b v-if="path == 'productList'">图片最佳尺寸：360px * 220px</b>
              <b v-if="path == 'adminHome'">图片最佳尺寸：88px * 88px</b>
            </template>
            <editor
              class="title"
              v-model="form[prop]"
              slot="title"
              slot-scope="{ prop }"
            >
            </editor>

            <editor
              v-model="form[prop]"
              slot="editor"
              slot-scope="{ prop }"
            ></editor>
            <div slot="tags" slot-scope="{ prop }">
              <div style="display: flex; flex-wrap: wrap; align-items: center">
                <div
                  style="width: 200px; margin-bottom: 5px; align-items: center"
                  class="flex"
                  :key="'tag' + idx"
                  v-for="(tag, idx) in form[prop]"
                >
                  <el-input v-model="form[prop][idx]"></el-input>
                  <el-button
                    style="margin: 0px 5px"
                    type="danger"
                    @click.prevent="removeTag(form[prop], idx)"
                  >
                    删除</el-button
                  >
                </div>
              </div>

              <el-button plain type="primary" @click="addTag(form[prop])"
                >新增</el-button
              >
            </div>
          </base-form>

          <div class="flex flex-center">
            <!-- <el-button type="primary" @click="$router.push('/news')">返回</el-button> -->
            <el-button type="primary" @click="submit">保存</el-button>
          </div>
        </el-tab-pane>
        <el-tab-pane
          v-if="$route.query.path == 'productList' && form.id"
          label="产品详情"
          name="2"
        >
          <viewEdit :listsId="form.id" ref="viewEdit"></viewEdit>
        </el-tab-pane>
      </el-tabs>
    </div>
  </div>
</template>

<script>
import editor from "@/components/editor";
import baseAside from "@/components/layout/aside";
import viewEdit from "./viewEdit.vue";
const path = config.BASEURL;
export default {
  components: {
    editor,
    baseAside,
    viewEdit,
  },
  data() {
    return {
      activeName: "1",
      query: {},
      action: path + "/main/uploads",
      path,
      form: {
        link: "",
        img: "",
        title_zh: "",
        title_en: "",
        subTitle_zh: "",
        tags_zh: [],
        tags_en: [],
      },
      routerList: {},
      defaultProps: {
        label: "name",
        id: "path",
        children: "children",
      },
      typeOptions: [
        { name: "International News", value: "国际" },
        { name: "Company News", value: "公司" },
      ],
      rules: {
        img: [{ required: true, message: "请上传图片", trigger: "blur" }],
        title_zh: [{ required: true, message: "请输入标题", trigger: "blur" }],
        content_zh: [
          { required: true, message: "请输入描述", trigger: "blur" },
        ],
        title_en: [
          { required: true, message: "请输入英文标题", trigger: "blur" },
        ],
        content_en: [
          { required: true, message: "请输入英文描述", trigger: "blur" },
        ],
      },
      formProps: {
        img: {
          slotName: "img",
          label: "配图",
          // isHide: () => {
          //   return this.query.path == "adminHome";
          // },
        },
        link: {
          label: "详情链接",
          slotName: "link",
          isHide: () => {
            return this.query.path == "productList";
          },
        },
        sort: {
          label: "排序",
          type: "input-number",
          attr: {
            min: 1,
          },
        },
        title_zh: {
          label: "标题(中文)",
          // type: "input",
          slotName: "title",
          attr: {
            placeholder: "请输入标题(中文)",
          },
          isHide: (prop, f, props) => {
            let p = props[prop];
            if (this.query.path == "adminHome") {
              p.type = "input";
              p.slotName = null;
            } else {
              p.type = null;
              p.slotName = "title";
            }
            return;
          },
        },
        subTitle_zh: {
          label: "副标题(中文)",
          type: "input",
          attr: {
            placeholder: "请输入副标题(中文)",
          },
          isHide: () => {
            return this.query.path == "adminHome";
          },
        },
        tags_zh: {
          label: "标签(中文)",
          slotName: "tags",
          isHide: () => {
            return this.query.path == "adminHome";
          },
        },
        content_zh: {
          label: "描述(中文)",
          slotName: "editor",
          attr: {
            rows: 10,
            type: "textarea",
            placeholder: "请输入描述(中文)",
          },
        },
        title_en: {
          label: "标题(英文)",
          // type: "input",
          slotName: "title",
          isHide: (prop, f, props) => {
            let p = props[prop];
            if (this.query.path == "adminHome") {
              p.type = "input";
              p.slotName = null;
            } else {
              p.type = null;
              p.slotName = "title";
            }
            return;
          },

          attr: {
            placeholder: "请输入标题(英文)",
          },
        },
        subTitle_en: {
          label: "副标题(英文)",
          type: "input",
          attr: {
            placeholder: "请输入副标题(英文)",
          },
          isHide: () => {
            return this.query.path == "adminHome";
          },
        },
        tags_en: {
          label: "标签(英文)",
          slotName: "tags",
          isHide: () => {
            return this.query.path == "adminHome";
          },
        },
        content_en: {
          label: "描述(英文)",
          slotName: "editor",
          attr: {
            rows: 10,
            type: "textarea",
            placeholder: "请输入描述(英文)",
          },
        },
      },
    };
  },
  created() {
    this.user = sessionStorage.getItem("user");
    if (this.user) {
      this.user = JSON.parse(this.user);
    }
    this.query = this.$route.query;
    if (this.query.id) {
      this.$api.listsById({ id: this.query.id }).then((res) => {
        this.form = res.data;
        this.form.tags_en = this.form.tags_en.split("_");
        this.form.tags_zh = this.form.tags_zh.split("_");
      });
    }
  },
  mounted() {
    this.getRouter();
  },
  methods: {
    selectLink(data) {
      console.log(data);
      this.form.link = data;
    },
    getRouter() {
      let router = this.$router;
      let options = JSON.stringify(router.options.routes[0]);
      options = options.replace(/isHide/g, "isHide1");
      options = JSON.parse(options);
      this.routerList = { url: options.path, menus: options.children };
    },
    addTag(arr) {
      arr.push("");
    },
    removeTag(arr, idx) {
      arr.splice(idx, 1);
    },
    goBack() {
      // this.$router.replace("/admin/productList");
      this.$router.go(-1);
    },
    handleAvatarSuccess(data) {
      this.form.img = data.data.path;
    },
    beforeAvatarUpload(file) {
      const isJPG = file.type === "image/jpeg" || file.type === "image/png";
      if (!isJPG) {
        this.$message.error("上传头像图片只能是 JPG 或 PNG 格式!");
      }
      return isJPG;
    },
    submit() {
      if (this.form.img == "" && this.query.path == "productList") {
        this.$message.error("请上传图片");
        return false;
      }
      let data = { ...this.form, page: this.query.path };
      this.$refs.form.validate().then((valid) => {
        if (data.tags_zh) data.tags_zh = data.tags_zh.join("_");
        if (data.tags_en) data.tags_en = data.tags_en.join("_");
        this.$api.listsSave(data).then(() => {
          this.$message.success(this.$t("success"));
          this.goBack();
        });
      });
    },
  },
};
</script>

<style lang="scss">
.news-edit {
  padding-top: 20px;
  .el-menu .el-menu-item {
    height: auto !important;
  }
  .el-form .el-form-item__label {
    float: none;
  }

  .el-input {
    height: 56px;
    line-height: 56px;
    .el-input__inner {
      height: 56px !important;
      line-height: 56px !important;
      font-size: 16px;
    }
    .el-input__suffix {
      display: none;
    }
  }
}
</style>
