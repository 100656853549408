<template>
  <div class="company">公司</div>
</template>

<script>
export default {

}
</script>

<style>

</style>