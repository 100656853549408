<template>
  <div class="page-content news-view">
    <h1 class="news-view-title">
      {{ $i18n.locale == "zh" ? newsInfo.title : newsInfo.title_en }}
      <p class="small">{{ formatDate(newsInfo.creattime) }}</p>
    </h1>
    <div class="news-view-img">
      <img :src="path + newsInfo.img" alt="" />
    </div>
    <div class="news-view-content">
      <div v-if="$i18n.locale == 'zh'" v-html="newsInfo.content"></div>
      <div v-else v-html="newsInfo.content_en"></div>
    </div>
    <div class="common-text">
      <h3>[{{ $t("contact.title") }}]</h3>
      <p>
        {{ $t("title") }}
      </p>
      <p>
        {{ $t("inquiry") }}：<el-link
          type="primary"
          @click="$router.push('/contact')"
        >
          {{ $t("click") }}
        </el-link>
      </p>
    </div>
  </div>
</template>

<script>
import { formatDate } from "@/utils/index"
export default {
  data() {
    return {
      path:config.BASEURL,
      newsInfo: {},
    }
  },
  created() {
    this.$route.query.id && this.getView(this.$route.query.id)
  },
  methods: {
    formatDate,
    getView(id) {
      this.$api.getNewsById({ id }).then((res) => {
        this.newsInfo = res.data
      })
    },
  },
}
</script>
<style lang="scss">
.news-view {
  &-title {
    font-size: calc(var(--font-size) * 1.6);
    color: $--color-primary;
    margin-bottom: 64px;
  }
  .small {
    margin-top: 24px;
    font-weight: 100;
    font-size: calc(var(--font-size) * 1.2);
  }
  &-content {
    width: calc(var(--page-width) - 400px);
    margin: 0 auto;
    font-size: calc(var(--font-size) * 1.5);
    min-height: 50vh;
    line-height: 40px;
    * {
      font-size: var(--font-size);
    }
  }
}
.news-view-img {
  text-align: center;
  img {
    width: calc(var(--page-width) - 400px);
    margin-bottom: 20px;
  }
}
.common-text {
  width: calc(var(--page-width) - 400px);
  margin: 0 auto;
  margin-top: 64px;
  font-size: var(--font-size);
  h3 {
    font-size: calc(var(--font-size) + 10px);
  }
  p {
    font-size: var(--font-size);
    display: flex;
    align-items: center;
    margin-top: 16px;
  }
  .el-link .el-link--inner {
    font-size: var(--font-size);
  }
}
</style>
