<template>
  <div class="page-content access">
    <h1 class="page-title">
      {{ $t("access.subTitle") }}
    </h1>
    <div class="desc" v-if="$i18n.locale == 'zh'" v-html="data.accessDesc"></div>
    <div class="desc" v-else v-html="data.accessDesc_en"></div>

    <div class="line-divider"></div>
    <h3 class="title">
      {{ $t("access.company") }}
    </h3>
    <el-row class="companyDesc" gutter="80">
      <el-col class="left" :span="9" :xs="24">
        <el-image :src="path + data.logo3" @click="$router.push('/')"></el-image>
        <p>{{ $t("addr") }}:{{$i18n.locale == 'zh'? data.address:data.address_en }}</p>
        <p>{{ $t("tel") }}:{{ data.phone }}</p>
        <p>{{ $t("email") }}:{{ data.email }}</p>
      </el-col>
      <el-col class="right" :span="15" :xs="24">
        <el-image
          :src="require('@/assets/img/map.png')"
          :preview-src-list="[require('@/assets/img/map.png')]"
        ></el-image>
      </el-col>
    </el-row>
  </div>
</template>

<script>
export default {
  data() {
    return {
      path:config.BASEURL,
      data: {}
    }
  },
  created() {
    this.getCompany();
  },
  methods: {
    getCompany() {
     this.$api.getCompany().then((res) => {
      this.data = res.data;
     })
}
  },
}
</script>
<style lang="scss">
.access {
  div.desc {
    margin-bottom: 119px;
    p {
      font-weight: 300;
      font-size: calc(var(--font-size) - 4px);
      line-height: 44px;
    }
    p + p {
      margin-top: 60px;
    }
  }
}
</style>
<style lang="scss" scoped>
.access {
  padding-top: 120px;
  .title {
    font-weight: 400;
    font-size: calc(var(--font-size) + 6px);
    margin-top: 50px;
    margin-bottom: 50px;
    color: $--color-primary;
  }
  .companyDesc {
    .left {
      margin-bottom: 20px;
      .el-image {
        width: 100%;
        height: 280px;
        margin-bottom: 18px;  
        cursor: pointer;
        ::v-deep img {
          object-fit: contain;
        }
      }
      p {
        font-size: calc(var(--font-size) - 6px);
        color: #4d4d4d;
        line-height: 40px;
      }
    }
    .right {
      .el-image {
        .el-image__inner {
          width: 100%;
          height: 480px;
        }
      }
    }
  }
}
</style>
