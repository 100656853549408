<template>
  <div class="page-content">
    <div
      v-for="(item, index) in moduleInfo.slice(0, 2)"
      class="module-item"
      :key="'_' + index"
    >
      <div class="link">
        <el-image
          class="img"
          :src="require('@/assets/img/' + item.img)"
        ></el-image>
      </div>
      <p>{{ $t(item.desc) }}</p>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      moduleInfo: [
        {
          desc: "productView.module1Desc",
          img: "product5.png",
        },
        {
          desc: "productView.module2Desc",
          img: "product6.png",
        },
      ],
    };
  },
};
</script>

<style lang="scss" scoped>
p {
  font-size:calc(var(--font-size) + 6px) ;
  text-align: center;
}
</style>