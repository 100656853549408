export const en = {
  lang: "CHINESE",
  title: "Butterfly Pharmaceutical Packaging",
  footerTitle: "南京巴特弗莱医药包装材料有限责任公司",
  info: "Pioneering technological innovation in Polymer Pre-fillable Syringe System <br/> Silicone-oil free, Simpler, Better",
  bannerBtn: "Company strength",
  address:
    "Building 9a, No.1 Shuibao Road, Lishui Economic Development Zone, Nanjing City, Jiangsu Province",
  addr: "Address",
  tel: "Tel",
  email: "Email",
  required: "Required",
  click: 'Click to go directly',
  inquiry: "Inquiry Form",

  return: "Return to Homepage",
  success: 'Success',
  more: "More",

  other: "Other",
  home: {
    title: "Home",
    product: "Products",
    company: "Company",
    productDesc:
      "Adopting the most advanced modular design and production technology, excellent product performance can help you control research and development costs, improve drug stability and safety.",
    companytDesc:
      "A series of high-performance, silicone free drug delivery systems exclusively designed and tailored for high-end injections by Nanjing Butterfly Pharmaceutical Packaging Materials Co., Ltd.",
    learnMore: "Learn more",
    readMore: "Learn more",
    other1: "Consistent & Reliable Quality",
    otherDesc1:
      "All production process under Grade A air supply cleanroom. In compliance with GMP and ISO 15378 requirements",
    other2: "Elaborate product design",
    otherDesc2: "Enabling companies to upgrade from borosilicate glass systems to the next gen high-performance polymer systems",
    other3: "Contact Us",
    otherDesc3:
      "If you have any questions, please contact us here",
    msg: "News",
    newsMore: "News",
  },
  product: {
    title: "Products",
    subTitle: "Product",
    desc: "The Crystal BioPure<sup>®</sup> series of high performance Polymer Pre-fillable Syringe are manufactured using the most advanced modular design technology, the simpler, yet high performace system could shorten your development cycle, providing you with lower R&D costs, and improve the stability and safety of drugs.",

    refineOn: "Refine On",
    subTitle1: "Product Characteristics",
    subTitle2: "Raw Material Characteristics",
    subTitle3: "Raw material performance",
  },
  productView: {
    title: "Product Introduction",
    subTitle: "Product Introduction",
    module1Desc: '100% In-line Camera Inspection',
    module2Desc: 'Manufactured in a Grade A Air Supply environment'
  },
  
  productView1: {
    title: "Product Introduction",
    subTitle: "Product Introduction",

   },
  product1: {
    title: "Crystal BioPure® Luer Lock",
    title1: "Crystal BioPure<sup>®</sup> Luer Lock",
    subTitle: "Luer Lock Polymer Pre-fillable Syringe",
    desc: `Ultra-low temperature freezing pharmaceuticals, Ophthalmic injections, 
Complex formulations (multivesicular liposomes, LNP, nanocrystals, etc.), Solvents for 
matching lyophilized powder for injections, etc.`,
    tags: "Specification: 0.5mL,1mL long, 3mL_Material:COC/COP_Lubrication: Silicone-oil free system",
  },
  product2: {
    title: "Crystal BioPure® Luer Lock",
    title1: "Crystal BioPure<sup>®</sup> Luer Lock",
    subTitle: "Luer Lock Polymer Pre-fillable Syringe with Dual-Spiral Channel",
    desc: "Ultra high viscosity fluids are guided by double threaded channels when flowing through a Ruhr cone, resulting in a longer flow path and relief of injection needle pressure, providing a better user experience",
    tags: "Optional specifications: 1mL extreme long_Material: COC_Lubrication: Silicone-oil free system",
  },
  product3: {
    title: "Crystal BioPure® Stack Needle",
    title1: "Crystal BioPure<sup>®</sup> Stack Needle",
    subTitle: "Stack Needle Polymer Pre-fillable Syringe",
    desc: "The stainless-steel needle is pre-placed into the injection mold before the injection molding process. The molten COP resin wraps around the stainless-steel needle to complete the molding process. No need for tungsten needles to create fluid path, No UV glue required.",
    tags: "Specification: 1mL long,  1mL standard ,2.25mL_Material:COP_Lubrication: Silicone-oil free system_No UV glue",
  },
  product4: {
    title: "Crystal BioPure® bespoke",
    title1: "Crystal BioPure<sup>®</sup> bespoke",
    subtitle: "Bespoke system",
    desc: "By using next-generation high-performance materials with unparalleled design freedom, we can meet your customized needs without silicone oil systems or UV curing adhesives"
  },
  news: {
    title: "News",
    click: "Click View",
    view: "Times"
  },

  newsView: {
    title: "newsView",
  },
  newsHome: {
    title: "news",
  },
  newsEdit: {
    title: "newsEdit",
  },
  access: {
    title: "About",
    subTitle: "About Us",
    desc: '<p>Butterfly Pharmaceutical Packaging Co., Ltd. (hereinafter referred to as "Butterfly") was established in 2022. As the first company in China to mass-produce silicone-oil free polymer pre-fillable syringe, Butterfly has built a cleanroom of over 300 square meters compliant with the latest domestic and international regulations & standards such as GMP, ISO 9001, ISO 15378, and ISO 13485. We have created an ISO Class 7 (Grade C) background cleanroom and an ISO Class 5 (Grade A) core operation cleanroom to provide clients with the highest standards of production environment. Additionally, we uses semiconductor industry particle control methods to reduce the risk of foreign particle adhesion. The subvisible particle and initial contamination bacteria levels of our lists are highly controllable, enabling us to continuously provide high-quality silicone-oil free polymer pre-fillable syringe to our clients.</p>',
    company: "Head Office",
  },
  contact: {
    title: "Contact Us",
    desc: "If you have any questions about Bart Fry, please contact us here.",
    steps:
      "Enter your inquiry details, confirm the content, and the inquiry has been successfully sent",
    formTitle: "Enter Your Inquiry Details",
    input: "Enter",
    select: "Select",
    companyname: "Company Name",
    companyVerify: "Maximum 30 characters",
    surname: "Surname",
    name: "Name",
    nameVerify: "Maximum 10 characters",
    country: "Country/Region",
    countryPlaceholder: "Select Country",
    address1: "Address 1",
    address2: "Address 2",
    city: "City",
    state: "State/Province",
    zip: "Postal Code",
    phone: "Phone",
    email: "Email",
    notes: "Notes/Reply",
    type: "Inquiry Type",
    typePlaceholder: "Select a Type",
    agree: "Accept our Privacy Policy",
    content: "Your Inquiry",
    contentPlaceholder: "Your question",
    contentVerify:
      "Please enter accurate information so we can provide an appropriate response",
    privacyPolicy: "Privacy Policy",
    privacyPolicyDesc:
      "The information collected through this form will be used to respond to inquiries about the company's lists, services, etc. After responding to the inquiry, we may process this information for the following purposes.\nCollected Information:\nCompany Name, Name, Country, Postal Code, State, City, Street Address, Building Name, Phone Number, Email Address, Inquiry Type, and Inquiry Details.\nNote: Please enter accurate information so we can provide an appropriate response. If you would like us to contact you by phone, please enter your phone number.\nPurposes of Use:\nTo respond appropriately to inquiries by confirming the country and company address\nTo provide information in response to inquiries and document requests about company lists through email, mail, phone, video conferencing, consultations, etc.\nTo engage in sales activities, marketing, and related business through contracts, company proposals, emails confirming orders, mail, phone, video conferencing, consultations, etc.\nTo conduct surveys to understand product development and quality improvement measures through email, mail, phone, video conferencing, consultations, etc.\nContract management, order processing, product delivery, providing support, invoicing\nTo respond to media inquiries or interviews through email, mail, phone, video conferencing, consultations, or other means.\nTo provide information in response to inquiries about recruitment through email, mail, phone, video conferencing, or other means.\nTo respond to other inquiries and make business improvements based on inquiries or requests through email, mail, phone, or other means.\nIf disclosure is required by legal requirements or court orders\nFor inquiries about personal information at our company and our handling of personal information for purposes other than those described here, please read our Privacy Statement (a written statement about the handling of personal information).",
    privacyPolicyDesc1:
      "To use this form, we need your consent to process your personal information. If you agree after reading this, please check the box below and fill out this form.",
    submit: "Confirm Content",
    edit: "Edit",
    send: "Send",
    return: "Return to Homepage",
    successTitle: "Inquiry Sent Successfully",
    successDesc:
      "Your inquiry has been sent successfully. Please keep your email and phone open for customer service to contact you.",
  },
  company: {
    title: "About Us",
    subTitle: "Company",
  },

  company1: {
    title: "Company Profile",
  },
  company2: {
    title: "Product Introduction",
  },
  company3: {
    title: "SERVICE",
  },
  admin: {
    title: "Admin",
  },
  login: {
    title: "Login",
  },
  user: {
    title: "User Info",
  },
  banner: {
    title: "Banner Management",
  },
  productEdit: {
    title: "product Desc",
  },
  productList: {
    title: "Product List",
  },
  adminHome: {
    title: "HOME",
  },
  lists: {
    title: "Product List",
  },
}
