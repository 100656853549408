<template>
  <div v-loading="loading">
    <base-form
      ref="form"
      formItemWidth="100%"
      labelWidth="130px"
      :form="form"
      :rules="rules"
      :formProps="formProps"
    >
      <div class="avatar-uploader" slot="img" slot-scope="{ prop }">
        <b>图片最佳尺寸：1202 * 600px</b>
        <el-upload
          class="avatar-uploader"
          :action="action"
          :show-file-list="false"
          :on-success="
            (data) => {
              handleAvatarSuccess(data, prop);
            }
          "
          :before-upload="beforeAvatarUpload"
        >
          <div>
            <img v-if="form[prop]" :src="path + form[prop]" class="avatar" />
            <i v-else class="el-icon-plus avatar-uploader-icon"></i>
          </div>
        </el-upload>

        <el-button
          v-if="form[prop]"
          type="danger"
          @click="
            () => {
              form[prop] = '';
              $forceUpdate();
            }
          "
          >删除
        </el-button>
      </div>
      <editor
        :ref="prop"
        slot="editor"
        v-model="form[prop]"
        slot-scope="{ prop }"
      >
      </editor>
      <el-row :gutter="10" slot="info">
        <el-col v-for="(item, idx) in info" :span="12" :key="item.en">
          <label>{{ item.zh }}</label>
          <editor
            v-if="form.info_zh[idx]"
            v-model="form.info_zh[idx].value"
          ></editor>
          <label>{{ item.en }}</label>
          <editor
            v-if="form.info_en[idx]"
            v-model="form.info_en[idx].value"
          ></editor>
        </el-col>
      </el-row>
      <el-row :gutter="10" slot="baseInfo" slot-scope="{ prop }">
        <div
          v-for="(item, idx) in form[prop + '_zh']"
          :key="'_zh' + idx + '_zh'"
        >
          <el-col :span="24">
            <h1 :key="idx">第{{ idx + 1 }}组数据</h1>
          </el-col>
          <el-col :span="10" :key="idx + '_zh'">
            <label>标题(中文)</label>

            <editor
              v-if="prop != 'info'"
              class="title"
              :ref="prop + '_zh' + idx + '_title'"
              v-model="form[prop + '_zh'][idx].title"
            ></editor>
            <el-input v-else v-model="form[prop + '_zh'][idx].title"></el-input>
            <template v-if="prop != 'info5'">
              <label>内容1(中文)</label>
              <editor
                :ref="prop + '_zh' + idx + '_content'"
                v-model="form[prop + '_zh'][idx].content"
              ></editor
            ></template>
            <template v-if="prop == 'info1' || prop == 'info3'">
              <label>内容2(中文)</label>
              <editor
                :ref="prop + '_zh' + idx + '_content1'"
                v-model="form[prop + '_zh'][idx].content1"
              ></editor
            ></template>
          </el-col>
          <el-col :span="10" :key="prop + idx">
            <label>标题(英文)</label>
            <editor
              class="title"
              v-if="prop != 'info'"
              :ref="prop + '_en' + idx + '_title'"
              v-model="form[prop + '_en'][idx].title"
            ></editor>
            <el-input v-else v-model="form[prop + '_en'][idx].title"></el-input>
            <template v-if="prop != 'info5'">
              <label>内容1(英文)</label>
              <editor
                :ref="prop + '_en' + idx + '_content'"
                v-model="form[prop + '_en'][idx].content"
              ></editor>
            </template>
            <template v-if="prop == 'info1' || prop == 'info3'">
              <label>内容2(英文)</label>
              <editor
                :ref="prop + '_en' + idx + '_content1'"
                v-model="form[prop + '_en'][idx].content1"
              ></editor>
            </template>
          </el-col>
          <el-col
            class="avatar-uploader"
            style="margin-top: 10px"
            v-if="prop == 'info5'"
            :span="20"
          >
            <b>图片最佳尺寸245 * 245px</b>
            <el-upload
              :action="action"
              :show-file-list="false"
              :on-success="
                (data) => {
                  form[prop + '_zh'][idx].content = data.data.path;
                  form[prop + '_en'][idx].content = data.data.path;
                  $forceUpdate();
                }
              "
              :before-upload="beforeAvatarUpload"
            >
              <template v-if="form[prop + '_zh'][idx].content">
                <img
                  :src="path + form[prop + '_zh'][idx].content"
                  class="avatar"
                />
              </template>
              <i v-else class="el-icon-plus avatar-uploader-icon"></i>
            </el-upload>

            <el-button
              v-if="form[prop + '_zh'][idx].content"
              type="danger"
              @click="
                () => {
                  form[prop + '_zh'][idx].content = '';
                  form[prop + '_en'][idx].content = '';
                  $forceUpdate();
                }
              "
              >删除
            </el-button>
          </el-col>
          <el-col :key="idx" :span="4">
            <el-button type="danger" plain @click="delInfo(prop, idx)">
              删除
            </el-button>
          </el-col>
        </div>
        <el-col :span="24">
          <el-button type="primary" plain @click="addInfo(prop)">
            新增
          </el-button>
        </el-col>
      </el-row>
    </base-form>

    <div class="flex flex-center">
      <!-- <el-button type="primary" @click="$router.push('/news')">返回</el-button> -->
      <el-button type="primary" @click="submit">保存</el-button>
    </div>
  </div>
</template>

<script>
import editor from "@/components/editor";
const path = config.BASEURL;
export default {
  props: ["listsId"],
  components: { editor },

  data() {
    return {
      loading: false,

      action: path + "/main/uploads",
      path,
      form: {
        name_zh: "",

        info_zh: [],
        info_en: [],
        info1_zh: [],
        info1_en: [],
        info2_zh: [],
        info2_en: [],
        info3_zh: [],
        info3_en: [],
        info4_zh: "",
        info4_en: "",
        info5_zh: [],
        info5_en: [],
      },
      rules: {},
      info: [
        {
          zh: "可选规格",
          en: "Specification",
        },
        {
          zh: "设计要求",
          en: "Design According to",
        },
        {
          zh: "材质",
          en: "Material",
        },
        {
          zh: "润滑方式",
          en: "Lubrication",
        },
        {
          zh: "灭菌方式",
          en: "Sterlization",
        },
        {
          zh: "产品应用",
          en: "Applications",
        },
      ],
      formProps: {
        name_zh: {
          label: "标题(中文)",
          slotName: "editor",
        },
        name_en: {
          label: "标题(英文)",
          slotName: "editor",
        },
        desc_zh: {
          label: "描述(中文)",
          slotName: "editor",
        },
        desc_en: {
          label: "描述(英文)",
          slotName: "editor",
        },
        info: {
          label: "产品规格",
          slotName: "baseInfo",
        },
        img1: {
          slotName: "img",
          label: "产品特性配图",
        },
        info1: {
          label: "产品特性",
          slotName: "baseInfo",
        },
        info2: {
          label: "原料特性",
          slotName: "baseInfo",
        },
        info3: {
          label: "产品成分",
          slotName: "baseInfo",
        },
        img2: {
          slotName: "img",
          label: "原料特性配图",
        },
        info4_zh: {
          label: "原料性能(中文)",
          slotName: "editor",
        },
        info4_en: {
          label: "原料性能(英文)",
          slotName: "editor",
        },
        info5: {
          label: "原料性能图表",
          slotName: "baseInfo",
        },
      },
    };
  },
  watch: {
    // listsId(val) {
    //   this.getView();
    // },
  },
  created() {},
  mounted() {
    this.listsId && this.getView();
  },
  methods: {
    addInfo(prop) {
      let obj = {
        info1: {
          title: "",
          content: "",
          content1: "",
        },
        info2: {
          title: "",
          content: "",
        },
        info3: {
          title: "",
          content: "",
          content1: "",
        },
      };
      this.form[prop + "_en"].push({ ...obj[prop] });
      this.form[prop + "_zh"].push({ ...obj[prop] });
    },
    delInfo(prop, idx) {
      this.form[prop + "_en"].splice(idx, 1);
      this.form[prop + "_zh"].splice(idx, 1);
    },
    handleAvatarSuccess(data, prop) {
      this.form[prop] = data.data.path;
      this.$forceUpdate();
    },
    beforeAvatarUpload(file) {
      const isJPG = file.type === "image/jpeg" || file.type === "image/png";
      if (!isJPG) {
        this.$message.error("上传头像图片只能是 JPG 或 PNG 格式!");
      }
      return isJPG;
    },
    getView() {
      this.loading = true;
      this.$api.getViewBylistsId(this.listsId).then((res) => {
        this.loading = false;
        const arrKeys = [
          "info_zh",
          "info_en",
          "info1_zh",
          "info1_en",
          "info2_zh",
          "info2_en",
          "info3_zh",
          "info3_en",
          "info5_zh",
          "info5_en",
        ];
        for (let key in res.data) {
          if (arrKeys.includes(key)) {
            let arr = res.data[key] ? JSON.parse(res.data[key]) : [];
            this.form[key] = arr;
            setTimeout(() => {
              arr.forEach((item, idx) => {
                let k = key + idx + "_content";
                item.content &&
                  this.$refs[k] &&
                  this.$refs[k][0].setHtml(item.content);
                let k1 = key + idx + "_content1";
                item.content1 &&
                  this.$refs[k1] &&
                  this.$refs[k1][0].setHtml(item.content1);
                let k2 = key + idx + "_title";
                item.title &&
                  this.$refs[k2] &&
                  this.$refs[k2][0].setHtml(item.title);
              });
            }, 10);
          } else {
            this.form[key] = res.data[key];
            // this.$set(this.form, key, res.data[key]);
          }
        }
        console.log(this.form, "getView");
      });
    },
    submit() {
      const data = { ...this.form };
      if (data.info_zh.length > 0) {
        data.info_zh = JSON.stringify(data.info_zh);
        data.info_en = JSON.stringify(data.info_en);
      } else {
        data.info_zh = "";
        data.info_en = "";
      }
      if (data.info1_zh.length > 0) {
        data.info1_zh = JSON.stringify(this.form.info1_zh);
        data.info1_en = JSON.stringify(this.form.info1_en);
      } else {
        data.info1_zh = "";
        data.info1_en = "";
      }
      if (data.info2_zh.length > 0) {
        data.info2_zh = JSON.stringify(this.form.info2_zh);
        data.info2_en = JSON.stringify(this.form.info2_en);
      } else {
        data.info2_zh = "";
        data.info2_en = "";
      }
      if (data.info3_zh.length > 0) {
        data.info3_zh = JSON.stringify(this.form.info3_zh);
        data.info3_en = JSON.stringify(this.form.info3_en);
      } else {
        data.info3_zh = "";
        data.info3_en = "";
      }
      if (data.info5_zh.length > 0) {
        data.info5_zh = JSON.stringify(this.form.info5_zh);
        data.info5_en = JSON.stringify(this.form.info5_en);
      } else {
        data.info5_zh = "";
        data.info5_en = "";
      }
      data.listsId = this.listsId;
      let info4_en = this.$refs.info4_en.editor.getText();
      let info4_zh = this.$refs.info4_zh.editor.getText();
      if (!info4_en) data.info4_en = "";
      if (!info4_zh) data.info4_zh = "";
      // console.log(data, "submit");
      this.$api.productSave(data).then((res) => {
        this.$message({
          type: "success",
          message: "保存成功",
        });
      });
    },
  },
};
</script>
