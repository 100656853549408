// 国际化
import Vue from "vue"
import vueI18n from "vue-i18n"
import enLocale from "element-ui/lib/locale/lang/en"
import zhLocale from "element-ui/lib/locale/lang/zh-CN"
import { en } from "./en.js"
import { zh } from "./zh.js"
Vue.use(vueI18n)
const messages = {
  en: {
    ...en,
    ...enLocale,
  },
  zh: {
    ...zh,
    ...zhLocale,
  },
}
export const i18n = new vueI18n({
  locale: localStorage.getItem("lang") || "zh", // set locale
  messages, // set locale messages
})
