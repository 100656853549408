const files = require.context("./", true, /index\.vue$/)
let modules = {}
files.keys().forEach((key) => {
  const file = files(key)
  if (!file) return
  const name = file.default.name
  if (!name) return
  modules[
    "temp" + name.slice(0, 1).toUpperCase() + name.slice(1, name.length)
  ] = file.default
})
export default modules
