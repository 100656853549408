<template>
  <div class="editor-box">
    <Toolbar
      class="toolbar"
      style="border-bottom: 1px solid #ccc"
      :editor="editor"
      :mode="mode"
    />
    <Editor
      class="editor"
      v-model="html"
      ref="qeditor"
      :defaultConfig="editorConfig"
      :mode="mode"
      @onCreated="onCreated"
      @onChange="onChange"
    />
  </div>
</template>

<script>
import { Editor, Toolbar } from "@wangeditor/editor-for-vue";
import "@wangeditor/editor/dist/css/style.css";
export default {
  props: {
    value: String,
    editorAttrs: {
      type: Object,
      default: () => {
        return {
          placeholder: "请输入内容...",
        };
      },
    },
  },
  components: { Editor, Toolbar },
  data() {
    return {
      editor: null,
      html: "",
      toolbarConfig: {
        toolbarKeys: [
          // 菜单 key
          "headerSelect",
          // 分割线
          "|",
          // 菜单 key
          "bold",
          "delIndent",
          "italic",
          "sup",
          "sub",
          // 菜单组，包含多个菜单
          {
            key: "group-more-style", // 必填，要以 group 开头
            title: "更多样式", // 必填
            iconSvg: "<svg>....</svg>", // 可选
            menuKeys: ["through", "code", "clearStyle"], // 下级菜单 key ，必填
          },
          // 继续配置其他菜单...
        ],
      },
      editorConfig: this.editorAttrs,
      mode: "default",
    };
  },
  watch: {
    value(value) {
      this.html = value;
    },
  },
  created() {
    this.editorConfig.scroll = true;
  },
  methods: {
    setHtml(val) {
      this.editor.setHtml(val);
      // return this.editor.setHtml(val);
    },
    onCreated(editor) {
      this.editor = Object.seal(editor); // 一定要用 Object.seal() ，否则会报错
      let img = this.editor.getMenuConfig("uploadImage");
      img.server = config.BASEURL + "/main/uploads";
      img.fieldName = "file";
      // 单个文件上传成功之后
      img.customInsert = (res, insertFn) => {
        res.data.url = config.BASEURL + res.data.path;
        this.$message.success("上传成功");
        insertFn(res.data.url);
      };
      let video = this.editor.getMenuConfig("uploadVideo");
      video.server = config.BASEURL + "/main/uploads";
      video.fieldName = "file";
      // 单个文件上传成功之后
      video.customInsert = (res, insertFn) => {
        res.data.url = config.BASEURL + res.data.path;
        this.$message.success("上传成功");
        insertFn(res.data.url);
      };
      video.onError = (a,e) => {
        this.$message.error(e);
      };
    },
    onChange(edit) {
      this.$emit("onChange", edit, this.html);
      this.$emit("input", this.html);
    },
    clear() {
      this.editor.clear();
    },
  },
  beforeDestroy() {
    const editor = this.editor;
    if (editor == null) return;
    editor.destroy(); // 组件销毁时，及时销毁编辑器
  },
};
</script>
<style lang="scss">
.title.editor-box .editor {
  height: 100px;
}
.editor-box {
  .editor{
    height:200px;
  }
  height: auto;
  position: relative;
  border: 1px solid $--border-color;
  border-radius: $--panel-radius;
  // .editor {
  //   position: absolute;
  //   top: 42px;
  //   left: 0px;
  //   right: 0px;
  //   bottom: 0px;
  // }
  .w-e-modal .babel-container {
    margin: 0px;
  }
  .w-e-modal .babel-container span {
    line-height: 1;
    margin: 0px;
  }
}
</style>
