<template>
  <div id="app">
    <router-view :key="$route.path"></router-view>
  </div>
</template>

<script>
export default {
  name: "App",
  components: {},
  mounted() {
    setTimeout(() => {
      this.setFont()
    }, 10)
    window.onresize = () => {
      this.setFont()
    }
    
  },
  methods: {
   
    preloadImages(arrayOfImages) {
      if (!arrayOfImages.length) {
        console.log("No images to preload")
        return
      }

      let loadedImages = 0

      // 遍历图片数组
      arrayOfImages.forEach((src) => {
        let img = new Image() // 创建一个新的Image对象
        img.onload = function () {
          // 当图片加载完成时执行
          loadedImages++
          console.log(`${src} loaded`)
          if (loadedImages === arrayOfImages.length) {
            // 所有图片加载完成
            console.log("All images have been loaded")
            // 这里可以调用其他函数，例如显示图片等
          }
        }

        img.onerror = function () {
          // 如果图片加载失败
          console.error(`Failed to load image: ${src}`)
        }

        img.src = src // 设置图片源，触发加载
      })
    },
    setFont() {
      let w = document.body.clientWidth
      let root = document.getElementsByTagName("html")[0]
      if (w < 1000 && w > 580) {
        root.style.fontSize = (w / 10) * 1.5 + "px"
      } else if (w <= 580) {
        root.style.fontSize = (w / 10) * 2 + "px"
      }
      console.log(w)
    },
  },
}
</script>

<style>
#app {
}
</style>
