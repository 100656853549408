<template>
  <div class="container">
    <div class="mobile-head">
      <router-link to="/">
        <img class="logo" :src="path + company.logo3" />
      </router-link>

      <div class="mobile-mune">
        <!-- 语言切换 -->
        <el-link :underline="false" class="lang" @click="changeLang">{{
          $t("lang")
        }}</el-link>
        <img src="@/assets/img/menu_tab.svg" @click="changeMune" />
      </div>
    </div>
    <div v-show="isOpen" class="mobile-aside">
      <div class="close">
        <i class="el-icon-close" @click="changeMune"></i>
      </div>
      <base-aside
        :defaultOpeneds="['产品']"
        mode="vertical"
        :active="$route.path"
        :routers="routerList"
      >
      </base-aside>
    </div>
    <!-- 首页 展示图-->
    <div v-if="$route.path == '/'" class="home-banner">
      <img class="img" :src="path + company.logo2" @click="$router.push('/')" />
      <h1>{{ $t("title") }}</h1>
      <p v-html="bannerInfo[$i18n.locale]"></p>
      <!-- <el-button
        type="primary"
        round
        class="btn"
        @click="$router.push('/access')"
      >
        {{ $t("bannerBtn") }}
        <i class="el-icon-arrow-right"></i>
      </el-button> -->
    </div>
    <div class="head">
      <base-head :title="$t('title')">
        <router-link slot="logo" class="flex" to="/">
          <img class="logo" :src="path + company.logo3"
        /></router-link>
        <div slot="header-right" class="mune">
          <base-aside :active="$route.path" ref="aside" :routers="routerList">
            <router-link class="collapse">
              <i class="el-icon-s-fold"></i>
            </router-link>
          </base-aside>
          <!-- 语言切换 -->
          <el-link :underline="false" class="lang" @click="changeLang">{{
            $t("lang")
          }}</el-link>
        </div>
      </base-head>
    </div>
    <el-container class="content" direction="horizontal">
      <el-container>
        <el-main class="main">
          <slot>
            <router-view :key="$route.path"></router-view>
          </slot>
        </el-main>
      </el-container>
    </el-container>
    <template>
      <breadcrumb
        v-if="$route.path != '/' && $route.name != '404'"
        :matcheds="matcheds"
      />
      <Footer :routers="routerList1" />
      <mobile-footer :routers="routerList" />
    </template>
  </div>
</template>

<script>
import baseHead from "./header";
import baseAside from "./aside";
import Footer from "./footer";
import mobileFooter from "./mobileFooter";
import breadcrumb from "./breadcrumb";

export default {
  name: "layout-index",
  components: {
    baseHead,
    baseAside,
    Footer,
    breadcrumb,
    mobileFooter,
  },
  data() {
    return {
      path: config.BASEURL,
      routerList: {},
      routerList1: [],
      matcheds: [],
      isOpen: false,
      bannerInfo: {},
      company: {},
    };
  },
  computed: {},
  watch: {},
  created() {
    let user = sessionStorage.getItem("user");
    if (user) {
      this.user = JSON.parse(user);
    }
    this.getBanner();
  },
  mounted() {
    // this.user = JSON.parse(sessionStorage.getItem("user"));
    let router = this.$router;
    let options = router.options.routes[0];
    this.routerList = { url: options.path, menus: options.children };
    this.matcheds = this.$route.matched;
    document.body.style.overflow = "visible";
    document.title = this.$t("footerTitle");
    this.getLists();
  },
  methods: {
    getLists() {
      this.$api.getLists({ page: "productList" }).then((res) => {
        let arr = [];
        if (res.data) {
          this.routerList.menus.forEach((val) => {
            if (val.name == "product") {
              res.data.forEach((v) => {
                arr.push({
                  title_zh: v.title_zh,
                  title_en: v.title_en,
                  subTitle_zh: v.subTitle_zh,
                  subTitle_en: v.subTitle_en,
                  path: `/productView/${v.id}`,
                });
              });
              val.children = arr;
              this.routerList1 = arr;
            }
          });
          console.log(this.routerList, "this.routerList");
        }
      });
    },
    getBanner() {
      this.$api.getBanner().then((res) => {
        if (res.data) {
          let opt = res.data.find((val) => val.page == "home");
          var style = document.createElement("style");
          style.innerHTML = `
    .home-banner::after {
        background-image: url('${
          config.BASEURL + opt.img
        }') !important; /* 新背景图 */
    }
`;
          document.head.appendChild(style);
          this.bannerInfo = {
            zh: opt.desc_zh.replace(/\n/g, "<br>"),
            en: opt.desc_en.replace(/\n/g, "<br>"),
          };
        }
      });
      this.$api.getCompany().then((res) => {
        this.company = res.data;
      });
    },
    changeLang() {
      const lang = localStorage.getItem("lang");
      this.$i18n.locale = lang == "zh" ? "en" : "zh";
      localStorage.setItem("lang", this.$i18n.locale);
      // 切换title
      document.title = this.$t("footerTitle");
    },
    changeMune() {
      this.isOpen = !this.isOpen;
      if (this.isOpen) {
        document.body.style.overflow = "hidden";
      } else {
        document.body.style.overflow = "visible";
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.breadcrumb + .el-main {
  padding: 0px $--padding $--padding $--padding;
}

@keyframes zoom {
  0% {
    transform: scale(1); /* 初始状态，图片原始大小 */
  }

  100% {
    transform: scale(1.1); /* 结束状态，图片放大到1.2倍 */
  }
}
@keyframes translateX {
  0% {
    transform: translateX(-100%); /* 初始状态，图片原始大小 */
  }

  100% {
    opacity: 1;
    transform: translateX(0); /* 结束状态，图片放大到1.2倍 */
  }
}
.container {
  .el-container {
    display: block;
  }
  .home-banner {
    padding-left: 680px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 88vh;
    max-height: 900px;
    position: relative;
    overflow: hidden;
    text-align: center;

    &::after {
      content: "";
      position: absolute;
      width: 100%;
      height: 100%;
      top: 0px;
      left: 0px;
      background: url(../../assets/img/home-banner.jpg) no-repeat;
      background-size: cover;
      background-position: center;
      animation: zoom 5s 1 forwards;
      z-index: 0;
    }
    .img {
      margin-top: 40px;
      margin-bottom: 10px;
      margin-left: 70px;
      border-radius: 100px;
      padding: 0px 70px 0px 70px;
      display: flex;
      align-items: center;
      height: 80px;
      z-index: 1;
      opacity: 0;
      transform: translateX(-100%);
      animation: translateX 5s 1 forwards;
      cursor: pointer;
      ::v-deep span {
        font-weight: 300;
        font-size: var(--font-size);
      }
    }
    h1 {
      font-weight: 500;
      font-size: calc(var(--font-size) * 2 + 6px);
      margin-bottom: 60px;
      z-index: 1;
      opacity: 0;
      transform: translateX(-100%);
      animation: translateX 5s 1 forwards;
      animation-delay: 0.5s;
      color: $--color-primary;
    }
    p {
      text-align: center;
      font-weight: 300;
      font-size: calc(var(--font-size) + 10px);
      color: #383375;
      line-height: 60px;
      // margin-bottom: 80px;
      opacity: 0;
      transform: translateX(-100%);
      z-index: 1;
      animation: translateX 5s 1 forwards;
      animation-delay: 3s;
    }
  }
  .head {
    display: flex;
    justify-content: center;
    box-shadow: $--box-shadow-base;
    position: sticky;
    height: 120px;
    width: 100%;
    top: 0px;
    left: 0px;
    z-index: 10;
    background: #fff;
    .lang {
      position: relative;
      padding-left: 30px;
      &::v-deep span {
        font-size: calc(var(--font-size) - 4px);
      }
      &::after {
        content: "";
        position: absolute;
        width: 2px;
        height: 30px;
        background: $--color-primary;
        left: 0px;
        top: 50%;
        transform: translateY(-50%);
      }
    }
    ::v-deep .el-menu .el-menu-item {
      padding-left: 30px !important;
      padding-right: 30px !important;
    }

    .submenu-full {
      background: #f5f5f5;
      box-shadow: 0px 5px 9px 1px rgba(56, 51, 117, 0.15);
      position: absolute;
      top: 0;
      left: 0px;
      width: 100%;
    }
  }
  .header {
    height: 100%;
    /* postcss-pxtorem: disable */
    width: var(--page-width);
    /* postcss-pxtorem: enable */
    ::v-deep .header-left {
      width: 208px;
      display: flex;
      align-items: center;
    }
    img {
      width: 208px;
      height: 50px;
      object-fit: contain;
    }
  }

  .mune {
    display: flex;
    justify-content: space-between;
    height: 100%;
    .tools {
      ::v-deep {
        .el-dropdown-link,
        .router-link--inner {
          color: $--color-primary;
        }
      }
    }
  }

  .content {
    min-height: 100vh;
    background-color: $--background-color-base;
    margin: 0 auto;
    position: relative;
    z-index: 0;
    .main {
      padding: 0px;
      overflow: visible;
    }
  }

  .collapse {
    position: absolute;
    bottom: 0px;
    right: $--margin;
    text-align: right;

    ::v-deep i {
      font-size: var(--font-size);
    }
  }
}
</style>
