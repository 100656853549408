<template>
  <base-dialog
    title="设置"
    ref="dialog"
    :modal="false"
    width="300px"
    :footer="true"
  >
    <div slot="headerTools"></div>
    <el-form ref="form" :model="form" :rules="rules">
      <el-form-item label="用户名" prop="username">
        <el-input v-model="form.username" placeholder="请输入用户名"></el-input>
      </el-form-item>
      <el-form-item label="密码" prop="password">
        <el-input
          type="password"
          v-model="form.password"
          placeholder="请输入用户名"
        ></el-input>
      </el-form-item>
    </el-form>
    <div slot="footer">
      <el-button type="primary" @click="save">保存</el-button>
      <el-button @click="$close()">取消</el-button>
    </div>
  </base-dialog>
</template>

<script>
export default {
  data() {
    return {
      form: {},
      rules: {
        username: [
          { required: true, message: "请输入用户名", trigger: "blur" },
        ],
        password: [{ required: true, message: "请输入密码", trigger: "blur" }],
      },
    }
  },
  methods: {
    $open() {
      this.$refs.dialog.$open()
      let user = sessionStorage.getItem("user")
      if (user) {
        this.form = JSON.parse(user)
      }
    },
    $close() {
      this.$refs.dialog.$close()
      this.form = {}
      this.$refs.form.clearValidate()
    },
    save() {
      this.$refs.dialog.$close()
      this.$api.saveAdmin(this.form).then(() => {
        this.$message.success("保存成功")
        const { id, username } = this.form
        sessionStorage.setItem("user", JSON.stringify({ id, username }))

        this.$close()
      })
    },
  },
}
</script>

<style></style>
