import { POST } from "@/utils/http.js";
export const productInfo = {
  1: {
    id: 8,
    zhName: "Crystal BioPure<sup>®</sup> Luer Lock 鲁尔锁预灌封注射器",
    enName: "Crystal BioPure<sup>®</sup> Luer Lock  Polymer Pre-fillable Syringe",
    zhDesc:
      "在巴特弗莱，Crystal BioPure<sup>®</sup> Luer Lock鲁尔锁预灌封注射器都配有高强度一体式锁定鲁尔接头",
    enDesc:
      "In Butterfly, Crystal BioPure ® Luer Lock Ruhr lock pre filled syringes are equipped with high-strength integrated locking Luer connectors",
    img1: "productView1.png",
    img2: "productView2.png",
    zhInfo: [
      {
        name: "可选规格",
        value: "0.5mL，1mL long，3mL",
      },
      {
        name: "设计要求",
        value: "ISO 11040-6",
      },
      {
        name: "材质",
        value: "COC(TOPAS®6013M-07)或COP(ZEONEX 690R)",
      },
      {
        name: "润滑方式",
        value: "无硅油系统",
      },
      {
        name: "灭菌方式",
        value: "EO（环氧乙烷）",
      },
      {
        name: "产品应用",
        value:
          "超低温冻存药品，眼科注射液，复杂制剂(多囊脂质体、LNP、纳米晶等)，配套冻干粉针的溶剂",
      },
    ],
    enInfo: [
      {
        name: "Specification",
        value: "0.5mL, 1mL long, 3mL",
      },
      {
        name: "Design According to",
        value: "ISO 11040-6",
      },
      {
        name: "Material",
        value: "COC(TOPAS®6013M-07)/COP(ZEONEX 690R)",
      },
      {
        name: "Lubrication",
        value: "Silicone-oil free system",
      },
      {
        name: "Sterlization",
        value: "EO",
      },
      {
        name: "Applications",
        value:
          `Ultra-low temperature freezing pharmaceuticals, Ophthalmic injections, 
Complex formulations (multivesicular liposomes, LNP, nanocrystals, etc.), Solvents for 
matching lyophilized powder for injections, etc.`
      },
    ],
    zhInfo1: [
      {
        title: "组合护帽",
        content:
          "在巴特弗莱<br/>Crystal BioPure<sup>®</sup> Luer Lock预灌封注射器都<br/>配有组合护帽",
        content1:
          "包含溴化丁基橡胶混聚异戊二烯橡胶内芯和<br/>聚丙烯(PP)外壳<br/>护帽不额外添加硅油",
      },
      {
        title: "无硅油全覆膜活塞",
        content:
          `巴特弗莱骄傲的与<img src="SHENGZOU"/><br/>
          开发出专用于Crystal BioPure<sup>®</sup>预灌封注射器<br/>
          系统的覆聚四氟乙烯膜氯化丁基橡胶活塞<br/>
          (Full Laminated Plunger Specific designfor Crystal BioPure<sup>®</sup>)
          `,
        content1: `极致纯净的配方
<br/>全覆膜设计
<br/>为您药品的长期储存提供纯净且稳定的保障
<br/>极小的启动力与稳定的持续滑动力`,
      },
      {
        title: "启动力和持续推动力",
        content:
          "在巴特弗莱<br/>Crystal BioPure<sup>®</sup> Luer Lock 预灌封注射器<br/>3mL搭配25G 5/8”注射针",
        content1:
          "测试溶液选择注射用水<br/>测试方法ISO 11040-6 Annex E<br/>启动力和持续推动力≤5N",
      },

      {
        title: "密封性保障",
        content:
          "在巴特弗莱<br/>灌封注射器组合件可以在压力、温度波<br/>动时提供更好的CCIT保护",
        content1:
          "常温下真空衰减法公称泄漏孔径<2.5μm<br/>-80℃条件下氦质谱法公称泄漏孔径<2.5μm",
      },
    ],
    enInfo1: [{
      title: "Rigid cap",
      content: `
        In Butterfly<br/>
Crystal BioPure<sup>®</sup> Luer Lock Pre-Fillable Syringe come with a rigid luer lock cap.`,
      content1: `Contains a bromobutyl rubber compounded polyisoprene rubber core and a polypropylene (PP) shell <br/>
No additional silicone oil added to the cap.`
    }, {
      title: "Full laminated silicone-oil free stopper",
      content:
        `Butterfly is proud to have developed with <img src="SHENGZOU"/> 
        <br/>a PTFE- laminated chlorobutyl plunger stopper specifically for pre-fillable syringe systems. 
<br/>(Full Laminated Plunger Specific design for Crystal BioPure<sup>®</sup>) `,
      content1: `Ultra-pure formula 
<br/>Full laminated design 
<br/>Provides exceptional purity and stability for long-term storage of your medicines 
<br/>Minimal start-up force and consistant gliding force`,
    },
    {
      title: "Glide Force",
      content:
        `In Butterfly
      <br/>the 3mL Crystal BioPure<sup>®</sup> Luer Lock syringe <br/> with 25G 5/8” needle. `,
      content1:
        `Test Solvent: Water for injection 
<br/>Test Method: ISO 11040-6 Annex E 
<br/>Glide Force: ≤5N`
    },

    {
      title: "CCIT Guarantee",
      content:
        `In Butterfly 
<br/>The silicone-oil free pre-fillable syringe system offers better CCIT capabilities against pressure and temperature fluctuations. `,
      content1:
        `-Nominal leakage pore size <2.5μm by vacuum decay at room temperature; 
<br/>-Nominal leakage pore size <2.5μm for helium leak spectrometry at -80°C.`
    },
    ],
    zhInfo2: [{
      title: "Crystal BioPure<sup>®</sup> 系列高分子（COC/COP）预灌封注射器采用的原料为",
      content:
        "COC粒子（标号：TOPAS® 6013M-07）<br/>COP粒子（标号：ZEONEX 690R) <br/>均符合欧盟药典、美国药典USP ClassVI和ISO10993对药用高分子材料的要求",
    }],
    enInfo2: [{
      title:
        "Crystal BioPure<sup>®</sup> Series Polymer Pre-Fillable Syringe (COC/COP)",
      content:
        "Cyclic olefin copolymer resin (TOPAS®6013M-07 )<br/> Cycloolefin polymer resin (ZEONEX 690R)  <br/>All comply with European Pharmacopoeia, USP Class VI and ISO 10993 for pharmaceutical polymers",
    }],
    zhInfo3: [
      {
        title: "环烯烃共聚物（COC）—TOPAS® 6013M-07",
        content: `FDA Drug Master File, DMF# 12132<br/>
FDA Device Master File, MAF# 1043<br/>
European Pharmacopoeia,Monograph 3.1.3. “Polyolefines”<br/>
USP 31 NF 26 <88> for the Biological Reactivity Tests, In Vivo, Class VI<br/>
USP 39 NF 34 <661.1> Plastic Materials of Construction`,
        content1: `ISO 10993-4:2002(Selection of tests for interactions with blood)<br/>
ISO 10993-6:2007 (Tests for local effects after implantation)<br/>
ISO 10993-10:2002 (Tests for skin sensitization)<br/>
ISO 10993-11:2006(Tests for systemic toxicity)`,
      },
      {
        title: "环烯烃聚合物（COP）—ZEONEX 690R",
        content: `FDA Drug Master File, DMF# 14084<br/>
USP 31 NF 26 <88> for the Biological Reactivity Tests, In Vivo, Class VI<br/>
ISO 10993-1:2018`,
        content1: `RoHS(The restriction of the use of certain hazardous substances<br/>
in electrical and electronic equipment)`,
      },
    ],
    enInfo3: [
      {
        title: "Cyclic Olefin Copolymer（COC）—TOPAS®6013M-07",
        content: `FDA Drug Master File, DMF# 12132<br/>
FDA Device Master File, MAF# 1043<br/>
European Pharmacopoeia,Monograph 3.1.3. “Polyolefines”<br/>
USP 31 NF 26 <88> for the Biological Reactivity Tests, In Vivo, Class VI<br/>
USP 39 NF 34 <661.1> Plastic Materials of Construction`,
        content1: `ISO 10993-4:2002(Selection of tests for interactions with blood)<br/>
ISO 10993-6:2007 (Tests for local effects after implantation)<br/>
ISO 10993-10:2002 (Tests for skin sensitization)<br/>
ISO 10993-11:2006(Tests for systemic toxicity)`,
      },
      {
        title: "CycloOlefin Polymer（COP）—ZEONEX 690R",
        content: `FDA Drug Master File, DMF# 14084<br/>
USP 31 NF 26 <88> for the Biological Reactivity Tests, In Vivo, Class VI<br/>
ISO 10993-1:2018`,
        content1: `RoHS(The restriction of the use of certain hazardous substances<br/>
in electrical and electronic equipment)`,
      },
    ],
    zhInfo4: "COC与COP以及中硼硅玻璃性能对比",
    enInfo4: "Performance comparison",
  },
  2: {
    id: 3,
    zhName: "Crystal BioPure<sup>®</sup> Luer Lock 鲁尔锁双螺纹预灌封注射器",
    enName: "Crystal BioPure<sup>®</sup> Luer Lock Polymer Pre-fillable Syringe with Dual-Spiral Channel",
    zhDesc:
      "在巴特弗莱，Crystal BioPure<sup>®</sup> Luer Lock 鲁尔锁双螺纹预灌封注射器，在鲁尔锥内部设计了双螺纹流道结构，以释放头部给药压力，提供更好的用户体验。",
    enDesc:
      "In Butterfly,Crystal BioPure<sup>®</sup>Luer Lock Pre-fillable Syringe with Dual-Spiral Channel, the duo-helix design inside the luer cone to release injection pressure to achieve a better user experience.",
    img1: "productView3.png",
    img2: "productView4.png",
    zhInfo: [
      {
        name: "可选规格",
        value: "1mL extremely long",
      },
      {
        name: "设计要求",
        value: "ISO 11040-6",
      },
      {
        name: "材质",
        value: "COC (TOPAS®6013M-07)",
      },
      {
        name: "润滑方式",
        value: "无硅油系统",
      },
      {
        name: "灭菌方式",
        value: "EO（环氧乙烷）",
      },
      {
        name: "产品应用",
        value: "超高粘度制剂，医美产品（透明质酸钠等）",
      },
    ],
    enInfo: [
      {
        name: "Specification",
        value: "1mL extremely long",
      },
      {
        name: "Design According to",
        value: "ISO 11040-6",
      },
      {
        name: "Material",

        value: "COC (TOPAS®6013M-07)",
      },
      {
        name: "Lubrication",
        value: "Silicone free system",
      },
      {
        name: "Sterlization",
        value: "EO (Ethylene Oxide)",
      },
      {
        name: "Applications",
        value:
          "Ultra-high viscosity medicine , aesthetic lists (hyaluronic acid, etc.)",
      },
    ],
    zhInfo1: [
      {
        title: "组合护帽",
        content: `在巴特弗莱，Crystal BioPure<sup>®</sup> Luer Lock<br/>预灌封注射器都配有组合护帽`,
        content1: `包含溴化丁基橡胶混聚异戊二烯橡胶内芯和<br/>聚丙烯(PP)外壳护帽不额外添加硅油`,
      },

      {
        title: "无硅油全覆膜活塞",
        content: `巴特弗莱骄傲的与<img src="SHENGZOU"/><br/>开发出专用于Crystal BioPure<sup>®</sup>预灌封注射器<br/>系统的覆聚四氟乙烯膜氯化丁基橡胶活塞<br/>(Full Laminated Plunger Specific designfor Crystal BioPure<sup>®</sup>)`,
        content1: `极致纯净的配方
<br/>全覆膜设计
<br/>为您药品的长期储存提供纯净且稳定的保障
<br/>极小的启动力与稳定的持续滑动力`,
      },
      {
        title: "鲁尔锁",
        content: `在巴特弗莱<br/>Crystal BioPure<sup>®</sup> Luer Lock鲁尔锁双螺纹预灌封<br/>注射器，都配有一体式锁定鲁尔接头`,
        content1: "设计符合ISO 80369-7<br/>提供了精确、安全的连接",
      },
      {
        title: "鲁尔锥",
        content: `在巴特弗莱<br/>Crystal BioPure<sup>®</sup> Luer Lock鲁尔锁双螺纹预灌封<br/>注射器，在鲁尔锥内部设计了双螺纹流道结构`,
        content1: `超高粘度流体在流经鲁尔锥时受到双螺纹<br/>流道引导，流动路径更长，注射针部位压力<br/>得到缓解，提供更好的用户体验`,
      },
      {
        title: "启动力和持续推动力",
        content: `在巴特弗莱，Crystal BioPure<sup>®</sup> Luer Lock<br/>鲁尔锁双螺纹预灌封注射器，1mL extremely long<br/>搭配 25G 1/2” 注射针`,
        content1: `测试溶液选择，粘度40,000cP的透明质酸<br/>钠溶液测试方法ISO 11040-6 Annex E<br/>启动力和持续推动力≤12.5N`,
      },
    ],
    enInfo1: [
      {
        title: "Rigid cap",
        content: `
          In Butterfly<br/>
  Crystal BioPure<sup>®</sup> Luer Lock Pre-Fillable Syringe come with a rigid luer lock cap.`,
        content1: `Contains a bromobutyl rubber compounded polyisoprene rubber core and a polypropylene (PP) shell <br/>
  No additional silicone oil added to the cap.`
      },

      {
        title: "Full laminated silicone-oil free stopper",
        content:
          `Butterfly is proud to have developed with <img src="SHENGZOU"/> 
          <br/>a PTFE- laminated chlorobutyl plunger stopper specifically for pre-fillable syringe systems. 
  <br/>(Full Laminated Plunger Specific design for Crystal BioPure<sup>®</sup>) `,
        content1: `Ultra-pure formula 
  <br/>Full laminated design 
  <br/>Provides exceptional purity and stability for long-term storage of your medicines 
  <br/>Minimal start-up force and consistant gliding force`,
      },
      {
        title: "Luer Lock",
        content: `In Butterfly<br/>Crystal BioPure ® Luer Lock Double Thread Pre Encapsulation<br/>Syringes are equipped with integrated locking Luer connectors`,
        content1:
          "Design complies with ISO 80369-7<br/>Provides precise and secure connection",
      },
      {
        title: "Luer Cone",
        content:
          `In Butterfly 
<br/>Crystal BioPure<sup>®</sup>Luer Lock Pre-fillable Syringe with Dual-Spiral Channel is designed with a duo helix flow path inside the luer cone;`,
        content1: `The ultra-high viscosity fluids are guided through the special designed Dual-Spiral Channel in the luer cone, the longer flow path to could guid the high viscosity fluids moving forward, while reliefing the injection pressure, resulting a better user experience. `
      },
      {
        title: "Glide Force",
        content: `
        In Butterfly
        <br/>the Crystal BioPure<sup>®</sup>Luer Lock Dua-Spiral Injection Channel Pre-Fillable Syringe 1mL extremely long with 25G 1/2” needle `,
        content1: `Test solvent:Viscosity 40,000 cP sodium hyaluronate solution 
<br/>Test Method: ISO11040-6 Annex E 
<br/>Glide Force:≤12.5N`
      },
    ],

    zhInfo2: [{
      title: "Crystal BioPure<sup>®</sup> 系列高分子（COC/COP）预灌封注射器采用的原料为",
      content:
        "COC粒子（标号：TOPAS® 6013M-07）<br/>COP粒子（标号：ZEONEX 690R) <br/>均符合欧盟药典、美国药典USP ClassVI和ISO10993对药用高分子材料的要求",
    }],
    enInfo2: [{
      title:
        "Crystal BioPure<sup>®</sup> Series Polymer Pre-Fillable Syringe (COC/COP)",
      content:
        "Cyclic olefin copolymer resin (TOPAS®6013M-07 )<br/> Cycloolefin polymer resin (ZEONEX 690R)  <br/>All comply with European Pharmacopoeia, USP Class VI and ISO 10993 for pharmaceutical polymers",
    }],
    zhInfo3: [
      {
        title: "环烯烃共聚物（COC）—TOPAS® 6013M-07",
        content: `FDA Drug Master File, DMF# 12132<br/>
FDA Device Master File, MAF# 1043<br/>
European Pharmacopoeia,Monograph 3.1.3. “Polyolefines”
USP 31 NF 26 <88> for the Biological Reactivity Tests, In Vivo, Class VI
USP 39 NF 34 <661.1> Plastic Materials of Construction`,
        content1: `ISO 10993-4:2002(Selection of tests for interactions with blood)<br/>
ISO 10993-6:2007 (Tests for local effects after implantation)<br/>
ISO 10993-10:2002 (Tests for skin sensitization)<br/>
ISO 10993-11:2006(Tests for systemic toxicity)`,
      },
      {
        title: "环烯烃聚合物（COP）—ZEONEX 690R",
        content: `FDA Drug Master File, DMF# 14084<br/>
USP 31 NF 26 <88> for the Biological Reactivity Tests, In Vivo, Class VI<br/>
ISO 10993-1:2018`,
        content1: `RoHS(The restriction of the use of certain hazardous substances<br/>
in electrical and electronic equipment)`,
      },
    ],
    enInfo3: [
      {
        title: "Cyclic Olefin Copolymer（COC）—TOPAS®6013M-07",
        content: `FDA Drug Master File, DMF# 12132<br/>
FDA Device Master File, MAF# 1043<br/>
European Pharmacopoeia,Monograph 3.1.3. “Polyolefines”
USP 31 NF 26 <88> for the Biological Reactivity Tests, In Vivo, Class VI
USP 39 NF 34 <661.1> Plastic Materials of Construction`,
        content1: `ISO 10993-4:2002(Selection of tests for interactions with blood)<br/>
ISO 10993-6:2007 (Tests for local effects after implantation)<br/>
ISO 10993-10:2002 (Tests for skin sensitization)<br/>
ISO 10993-11:2006(Tests for systemic toxicity)`,
      },
      {
        title: "CycloOlefin Polymer（COP）—ZEONEX 690R",
        content: `FDA Drug Master File, DMF# 14084<br/>
        USP 31 NF 26 <88> for the Biological Reactivity Tests, In Vivo, Class VI<br/>
        ISO 10993-1:2018`,
        content1: `RoHS(The restriction of the use of certain hazardous substances<br/>
        in electrical and electronic equipment)`,
      },
    ],
    zhInfo4: "COC与COP以及中硼硅玻璃性能对比",
    enInfo4: "Performance comparison",
  },
  3: {

    id: 4,
    zhName: "Crystal BioPure<sup>®</sup> Stack Needle 插针预灌封注射器",
    enName: "Crystal BioPure<sup>®</sup> Stack Needle Polymer Pre-fillable Syringe",
    zhDesc:
      "在巴特弗莱，Crystal BioPure<sup>®</sup> Stack Needle 插针预灌封注射器1mL long搭配27G 1/2” 注射针",
    enDesc:
      'In Butterfly , 1mL long Crystal BioPure<sup>®</sup>  Stack Needle Pre-fillable Syringe with 27G 1/2” Needle',
    img1: "productView5.png",
    img2: "productView6.png",
    zhInfo: [
      {
        name: "注射器规格",
        value: "1mL long，1mL standard，2.25mL",
      },
      {
        name: "注射器规格",
        value: "27G 1/2”, 29G 1/2”, 25G 5/8”",
      },
      {
        name: "设计要求",
        value: "ISO 11040-4",
      },
      {
        name: "材质",
        value: "COP（ZEONEX 690R）",
      },
      {
        name: "润滑方式",
        value: "无硅油系统（全覆膜/镀膜可选）",
      },
      {
        name: "灭菌方式",
        value: "X-ray(X射线)",
      },
      {
        name: "产品应用",
        value: "生物制剂，GLP-1类药物，疫苗，皮下注射药物",
      },
    ],
    enInfo: [
      {
        name: "Specification",
        value: "1mL long，1mL standard，2.25mL",
      },
      {
        name: "Needle size",
        value: "27G 1/2”, 29G 1/2”, 25G 5/8”",
      },
      {
        name: "Design According to",
        value: "ISO 11040-4",
      },
      {
        name: "Material",
        value: "COP（ZEONEX 690R）",
      },
      {
        name: "Lubrication",
        value: "Silicone-oil free system（Full laminated stopper/ Coated Stopper）",
      },
      {
        name: "Sterlization",
        value: "X-ray",
      },
      {
        name: "Applications",
        value:
          "Biologics, GLP-1 drugs, vaccines, hypodermic drugs",
      },
    ],
    zhInfo1: [
      {
        title: "一体成型注射针",
        content: `在巴特弗莱<br/>
Crystal BioPure<sup>®</sup> Stack Needle预灌封注射器<br/>
都配有一体成型注射针`,
        content1: `注射针在注射器注塑成型时放入注塑模具<br/>
熔融的COP树脂包裹着注射针完成塑化过程<br/>
无需钨针制造流道，无需UV胶水`,
      },
      {
        title: "高强度法兰",
        content: `在巴特弗莱<br/>
Crystal BioPure<sup>®</sup> Stack Needle预灌封注射器<br/>
都配有高强度法兰`,
        content1: `测试方法ISO 11040-6 Annex C<br/>
法兰耐破损强度≥150N<br/>
完美匹配Autoinjector`,
      },
      {
        title: "启动力和持续推动力",
        content: `在巴特弗莱<br/>
Crystal BioPure<sup>®</sup> Stack Needle预灌封注射器<br/>
1mL long搭配27G 1/2” 注射针`,
        content1: `测试溶液选择注射用水<br/>
测试方法ISO 11040-6 Annex E<br/>
启动力和持续推动力≤3N`,
      },

      {
        title: "无硅油全覆膜活塞",
        content: `巴特弗莱骄傲的与<img src="SHENGZOU"/><br/>
开发出专用于Crystal BioPure<sup>®</sup>预灌封注射器<br/>系统的覆聚四氟乙烯膜氯化丁基橡胶活塞<br/>(Full Laminated Plunger Specific designfor Crystal BioPure<sup>®</sup>)`,
        content1: `极致纯净的配方
        <br/>全覆膜设计
        <br/>为您药品的长期储存提供纯净且稳定的保障
        <br/>极小的启动力与稳定的持续滑动力`,
      },

    ],
    enInfo1: [
      {
        title: "The intergrated injection needle",
        content: `In Butterfly
        <br/>Crystal BioPure<sup>®</sup> Stack Needle Pre-Fillable Syringe
        <br/>All equipped with an The intergrated injection needle`,
        content1: `
Equipped with an intergrated needle, the stainless-steel needle is pre-placed into the injection mold during syringe injection molding. The molten COP resin wraps around the injection needle to complete the molding process. 
No need for tungsten needles to create fluid runners
No UV glue required`,
      },
      {
        title: "High strength flanges",
        content: `In Butterfly 
        <br/>Crystal BioPure<sup>®</sup> Stack Needle Pre-Fillable Syringe is equipped with high strength flange`,
        content1: `Test Method：ISO 11040-6 Annex C 
 <br/>Flange breaking strength≥150N 
 <br/>Perfectly suits Autoinjector`,
      },
      {
        title: "Glide Forcee",
        content: `In Butterfly <br/> the 1mL long Crystal BioPure<sup>®</sup>  Stacked Needle Pre-fillable Syringe with 27G 1/2” Needle`,
        content1: `Test Solvent: Water for injection 
<br/>Test Method: ISO 11040-6 Annex E 
<br/>Glide Force: ≤3N`,
      }, {
        title: "Full laminated silicone-oil free stopper",
        content:
          `Butterfly is proud to have developed with <img src="SHENGZOU"/> 
          <br/>a PTFE- laminated chlorobutyl plunger stopper specifically for pre-fillable syringe systems. 
  <br/>(Full Laminated Plunger Specific design for Crystal BioPure<sup>®</sup>) `,
        content1: `Ultra-pure formula 
  <br/>Full laminated design 
  <br/>Provides exceptional purity and stability for long-term storage of your medicines 
  <br/>Minimal start-up force and consistant gliding force`,
      },
    ],

    zhInfo2: [{
      title: "Crystal BioPure<sup>®</sup> 系列高分子（COC/COP）预灌封注射器采用的原料为",
      content:
        "COC粒子（标号：TOPAS® 6013M-07）<br/>COP粒子（标号：ZEONEX 690R) <br/>均符合欧盟药典、美国药典USP ClassVI和ISO10993对药用高分子材料的要求",
    }],
    enInfo2: [{
      title:
        "Crystal BioPure<sup>®</sup> Series Polymer Pre-Fillable Syringe (COC/COP)",
      content:
        "Cyclic olefin copolymer resin (TOPAS®6013M-07 )<br/> Cycloolefin polymer resin (ZEONEX 690R)  <br/> All comply with European Pharmacopoeia, USP Class VI and ISO 10993 for pharmaceutical polymers",
    }],
    zhInfo3: [
      {
        title: "环烯烃共聚物（COC）—TOPAS® 6013M-07",
        content: `FDA Drug Master File, DMF# 12132<br/>
FDA Device Master File, MAF# 1043<br/>
European Pharmacopoeia,Monograph 3.1.3. “Polyolefines”
USP 31 NF 26 <88> for the Biological Reactivity Tests, In Vivo, Class VI
USP 39 NF 34 <661.1> Plastic Materials of Construction`,
        content1: `ISO 10993-4:2002(Selection of tests for interactions with blood)<br/>
ISO 10993-6:2007 (Tests for local effects after implantation)<br/>
ISO 10993-10:2002 (Tests for skin sensitization)<br/>
ISO 10993-11:2006(Tests for systemic toxicity)`,
      },
      {
        title: "环烯烃聚合物（COP）—ZEONEX 690R",
        content: `FDA Drug Master File, DMF# 14084<br/>
USP 31 NF 26 <88> for the Biological Reactivity Tests, In Vivo, Class VI<br/>
ISO 10993-1:2018`,
        content1: `RoHS(The restriction of the use of certain hazardous substances<br/>
in electrical and electronic equipment)`,
      },
    ],
    enInfo3: [
      {
        title: "Cyclic Olefin Copolymer（COC）—TOPAS®6013M-07",
        content: `FDA Drug Master File, DMF# 12132<br/>
FDA Device Master File, MAF# 1043<br/>
European Pharmacopoeia,Monograph 3.1.3. “Polyolefines”
USP 31 NF 26 <88> for the Biological Reactivity Tests, In Vivo, Class VI
USP 39 NF 34 <661.1> Plastic Materials of Construction`,
        content1: `ISO 10993-4:2002(Selection of tests for interactions with blood)<br/>
ISO 10993-6:2007 (Tests for local effects after implantation)<br/>
ISO 10993-10:2002 (Tests for skin sensitization)<br/>
ISO 10993-11:2006(Tests for systemic toxicity)`,
      },
      {
        title: "CycloOlefin Polymer（COP）—ZEONEX 690R",
        content: `FDA Drug Master File, DMF# 14084<br/>
        USP 31 NF 26 <88> for the Biological Reactivity Tests, In Vivo, Class VI<br/>
        ISO 10993-1:2018`,
        content1: `RoHS(The restriction of the use of certain hazardous substances<br/>
        in electrical and electronic equipment)`,
      },
    ],
    zhInfo4: "COC与COP以及中硼硅玻璃性能对比",
    enInfo4: "Performance comparison",
  },
}

export let imgs = [
  `https://v1.ax1x.com/2024/08/01/7GxpS5.jpg,
https://v1.ax1x.com/2024/08/01/7Gx7j9.jpg,
https://v1.ax1x.com/2024/08/01/7GxLsm.jpg,
https://v1.ax1x.com/2024/08/01/7Gx374.jpg,
https://v1.ax1x.com/2024/08/01/7Gxmwj.jpg,
https://v1.ax1x.com/2024/08/01/7GxlWh.jpg,
https://v1.ax1x.com/2024/08/01/7GxQmY.jpg,
https://v1.ax1x.com/2024/08/01/7GxN8H.jpg,
https://v1.ax1x.com/2024/08/01/7GxqrZ.jpg,
https://v1.ax1x.com/2024/08/01/7GxueU.jpg,
https://v1.ax1x.com/2024/08/01/7GxHSs.jpg,
https://v1.ax1x.com/2024/08/01/7Gxyyq.jpg,
https://v1.ax1x.com/2024/08/01/7GxKsa.jpg,
https://v1.ax1x.com/2024/08/01/7GxgE7.jpg,
https://v1.ax1x.com/2024/08/01/7GxhWI.jpg,
https://v1.ax1x.com/2024/08/01/7Gx8jV.jpg,
https://v1.ax1x.com/2024/08/01/7Gx58J.jpg,
https://v1.ax1x.com/2024/08/01/7GxxpL.jpg,
https://v1.ax1x.com/2024/08/01/7GxAtG.jpg,
https://v1.ax1x.com/2024/08/01/7GxVoB.jpg,
https://v1.ax1x.com/2024/08/01/7Gxcyt.jpg,
https://v1.ax1x.com/2024/08/01/7GxvUb.jpg,
https://v1.ax1x.com/2024/08/01/7GxOEP.jpg,
https://v1.ax1x.com/2024/08/01/7Gxase.jpg,
https://v1.ax1x.com/2024/08/01/7GxScw.jpg,
https://v1.ax1x.com/2024/08/01/7GxUj6.jpg,
https://v1.ax1x.com/2024/08/01/7GxPpO.jpg,
https://v1.ax1x.com/2024/08/01/7GxRGQ.jpg,
https://v1.ax1x.com/2024/08/01/7GxYtf.jpg,
https://v1.ax1x.com/2024/08/01/7Gxroc.jpg,
https://v1.ax1x.com/2024/08/01/7GxzH3.jpg,
https://v1.ax1x.com/2024/08/01/7Gx4Uj.jpg,
https://v1.ax1x.com/2024/08/01/7GxC25.jpg,
https://v1.ax1x.com/2024/08/01/7Gxjc4.jpg,
https://v1.ax1x.com/2024/08/01/7GxXQm.jpg,
https://v1.ax1x.com/2024/08/01/7Gxnnh.jpg,
https://v1.ax1x.com/2024/08/01/7GxML9.jpg,
https://v1.ax1x.com/2024/08/01/7GxfGY.jpg,
https://v1.ax1x.com/2024/08/01/7GxizH.jpg,
https://v1.ax1x.com/2024/08/01/7GxsoZ.jpg,
https://v1.ax1x.com/2024/08/01/7GxF1q.jpg,
https://v1.ax1x.com/2024/08/01/7Gx6HU.jpg,
https://v1.ax1x.com/2024/08/01/7GxZ2s.jpg,
https://v1.ax1x.com/2024/08/01/7GxeQa.jpg,
https://v1.ax1x.com/2024/08/01/7Gxov7.jpg,
https://v1.ax1x.com/2024/08/01/7G59nI.jpg,
https://v1.ax1x.com/2024/08/01/7G5TxL.jpg,
https://v1.ax1x.com/2024/08/01/7G5BLV.jpg,
https://v1.ax1x.com/2024/08/01/7G5mzJ.jpg,
https://v1.ax1x.com/2024/08/01/7G5L9G.jpg,
https://v1.ax1x.com/2024/08/01/7G50KB.jpg,
https://v1.ax1x.com/2024/08/01/7G531t.jpg,
https://v1.ax1x.com/2024/08/01/7G5EQe.jpg,
https://v1.ax1x.com/2024/08/01/7G5l6b.jpg,
https://v1.ax1x.com/2024/08/01/7G5NIw.jpg,
https://v1.ax1x.com/2024/08/01/7G5QvP.jpg,
https://v1.ax1x.com/2024/08/01/7G5uL6.jpg,
https://v1.ax1x.com/2024/08/01/7G5wxO.jpg,
https://v1.ax1x.com/2024/08/01/7G5y4Q.jpg,
https://v1.ax1x.com/2024/08/01/7G5K9f.jpg`
  ,
  `https://v1.ax1x.com/2024/08/01/7G5h6j.jpg,
https://v1.ax1x.com/2024/08/01/7G5GN5.jpg,
https://v1.ax1x.com/2024/08/01/7G5gP3.jpg,
https://v1.ax1x.com/2024/08/01/7G55I4.jpg,
https://v1.ax1x.com/2024/08/01/7G5bKc.jpg,
https://v1.ax1x.com/2024/08/01/7G5xam.jpg,
https://v1.ax1x.com/2024/08/01/7G5V0h.jpg,
https://v1.ax1x.com/2024/08/01/7G5W59.jpg,
https://v1.ax1x.com/2024/08/01/7G5c4Y.jpg,
https://v1.ax1x.com/2024/08/01/7G5akH.jpg,
https://v1.ax1x.com/2024/08/01/7G5DKZ.jpg,
https://v1.ax1x.com/2024/08/01/7G5OPU.jpg,
https://v1.ax1x.com/2024/08/01/7G5SFq.jpg,
https://v1.ax1x.com/2024/08/01/7G51Ns.jpg,
https://v1.ax1x.com/2024/08/01/7G5Paa.jpg,
https://v1.ax1x.com/2024/08/01/7G5RM7.jpg,
https://v1.ax1x.com/2024/08/01/7G5r0I.jpg,
https://v1.ax1x.com/2024/08/01/7G5t5V.jpg,
https://v1.ax1x.com/2024/08/01/7G5zCL.jpg,
https://v1.ax1x.com/2024/08/01/7G5CkJ.jpg,
https://v1.ax1x.com/2024/08/01/7G5XRB.jpg,
https://v1.ax1x.com/2024/08/01/7G5JbG.jpg,
https://v1.ax1x.com/2024/08/01/7G5jFt.jpg,
https://v1.ax1x.com/2024/08/01/7G5Iqb.jpg,
https://v1.ax1x.com/2024/08/01/7G5Mae.jpg,
https://v1.ax1x.com/2024/08/01/7G5fMP.jpg,
https://v1.ax1x.com/2024/08/01/7G5s3w.jpg,
https://v1.ax1x.com/2024/08/01/7G5256.jpg,
https://v1.ax1x.com/2024/08/01/7G56CO.jpg,
https://v1.ax1x.com/2024/08/01/7G5ZBQ.jpg,
https://v1.ax1x.com/2024/08/01/7G5dbf.jpg,
https://v1.ax1x.com/2024/08/01/7G5eRc.jpg,
https://v1.ax1x.com/2024/08/01/7G5oZ3.jpg,
https://v1.ax1x.com/2024/08/01/7GAkqj.jpg,
https://v1.ax1x.com/2024/08/01/7GABD5.jpg,
https://v1.ax1x.com/2024/08/01/7GATfm.jpg,
https://v1.ax1x.com/2024/08/01/7GAp34.jpg,
https://v1.ax1x.com/2024/08/01/7GALAh.jpg,
https://v1.ax1x.com/2024/08/01/7GA0J9.jpg,
https://v1.ax1x.com/2024/08/01/7GAlBY.jpg,
https://v1.ax1x.com/2024/08/01/7GA7gH.jpg,
https://v1.ax1x.com/2024/08/01/7GAQZU.jpg,
https://v1.ax1x.com/2024/08/01/7GAERZ.jpg,
https://v1.ax1x.com/2024/08/01/7GAquq.jpg,
https://v1.ax1x.com/2024/08/01/7GAuDs.jpg,
https://v1.ax1x.com/2024/08/01/7GAwfa.jpg,
https://v1.ax1x.com/2024/08/01/7GAHl7.jpg,
https://v1.ax1x.com/2024/08/01/7GAKAI.jpg,
https://v1.ax1x.com/2024/08/01/7GAbJV.jpg,
https://v1.ax1x.com/2024/08/01/7GAhTL.jpg,
https://v1.ax1x.com/2024/08/01/7GA8gJ.jpg,
https://v1.ax1x.com/2024/08/01/7GAGYG.jpg,
https://v1.ax1x.com/2024/08/01/7GAxdB.jpg,
https://v1.ax1x.com/2024/08/01/7GAAut.jpg,
https://v1.ax1x.com/2024/08/01/7GAVOb.jpg,
https://v1.ax1x.com/2024/08/01/7GAWfe.jpg,
https://v1.ax1x.com/2024/08/01/7GAvlP.jpg,
https://v1.ax1x.com/2024/08/01/7GAaVw.jpg,
https://v1.ax1x.com/2024/08/01/7GADJ6.jpg,
https://v1.ax1x.com/2024/08/01/7GASTO.jpg`,
  `https://v1.ax1x.com/2024/08/01/7GAYw3.jpg,
https://v1.ax1x.com/2024/08/01/7GArOj.jpg,
https://v1.ax1x.com/2024/08/01/7GA1Yf.jpg,
https://v1.ax1x.com/2024/08/01/7GAPdc.jpg,
https://v1.ax1x.com/2024/08/01/7GAUhQ.jpg,
https://v1.ax1x.com/2024/08/01/7GAti5.jpg,
https://v1.ax1x.com/2024/08/01/7GA47m.jpg,
https://v1.ax1x.com/2024/08/01/7GACV4.jpg,
https://v1.ax1x.com/2024/08/01/7GAJXh.jpg,
https://v1.ax1x.com/2024/08/01/7GAjm9.jpg,
https://v1.ax1x.com/2024/08/01/7GAnhY.jpg,
https://v1.ax1x.com/2024/08/01/7GAIrH.jpg,
https://v1.ax1x.com/2024/08/01/7GAMdZ.jpg,
https://v1.ax1x.com/2024/08/01/7GAiwU.jpg,
https://v1.ax1x.com/2024/08/01/7GAsSq.jpg,
https://v1.ax1x.com/2024/08/01/7GA2is.jpg,
https://v1.ax1x.com/2024/08/01/7GAF7a.jpg,
https://v1.ax1x.com/2024/08/01/7GAZW7.jpg,
https://v1.ax1x.com/2024/08/01/7GAdXI.jpg,
https://v1.ax1x.com/2024/08/01/7GAomV.jpg,
https://v1.ax1x.com/2024/08/01/7GV98L.jpg,
https://v1.ax1x.com/2024/08/01/7GVkrJ.jpg,
https://v1.ax1x.com/2024/08/01/7GVBeG.jpg,
https://v1.ax1x.com/2024/08/01/7GVmyB.jpg,
https://v1.ax1x.com/2024/08/01/7GVpSt.jpg,
https://v1.ax1x.com/2024/08/01/7GVLsb.jpg,
https://v1.ax1x.com/2024/08/01/7GV37e.jpg,
https://v1.ax1x.com/2024/08/01/7GVlWP.jpg,
https://v1.ax1x.com/2024/08/01/7GV7jw.jpg,
https://v1.ax1x.com/2024/08/01/7GVQm6.jpg,
https://v1.ax1x.com/2024/08/01/7GVN8O.jpg,
https://v1.ax1x.com/2024/08/01/7GVqtQ.jpg,
https://v1.ax1x.com/2024/08/01/7GVyyc.jpg,
https://v1.ax1x.com/2024/08/01/7GVuef.jpg,
https://v1.ax1x.com/2024/08/01/7GVHU3.jpg,
https://v1.ax1x.com/2024/08/01/7GVKsj.jpg,
https://v1.ax1x.com/2024/08/01/7GVgE5.jpg,
https://v1.ax1x.com/2024/08/01/7GVhcm.jpg,
https://v1.ax1x.com/2024/08/01/7GV8j4.jpg,
https://v1.ax1x.com/2024/08/01/7GVxph.jpg,
https://v1.ax1x.com/2024/08/01/7GV5G9.jpg,
https://v1.ax1x.com/2024/08/01/7GVAtY.jpg,
https://v1.ax1x.com/2024/08/01/7GVVoH.jpg,
https://v1.ax1x.com/2024/08/01/7GVcyZ.jpg,
https://v1.ax1x.com/2024/08/01/7GVvUU.jpg,
https://v1.ax1x.com/2024/08/01/7GVa2q.jpg,
https://v1.ax1x.com/2024/08/01/7GVOEs.jpg,
https://v1.ax1x.com/2024/08/01/7GVSca.jpg,
https://v1.ax1x.com/2024/08/01/7GVUn7.jpg,
https://v1.ax1x.com/2024/08/01/7GVPpI.jpg,
https://v1.ax1x.com/2024/08/01/7GVRGV.jpg,
https://v1.ax1x.com/2024/08/01/7GVYzL.jpg,
https://v1.ax1x.com/2024/08/01/7GVroJ.jpg,
https://v1.ax1x.com/2024/08/01/7GVzHG.jpg,
https://v1.ax1x.com/2024/08/01/7GV41B.jpg,
https://v1.ax1x.com/2024/08/01/7GVC2t.jpg,
https://v1.ax1x.com/2024/08/01/7GVXQb.jpg,
https://v1.ax1x.com/2024/08/01/7GVjce.jpg,
https://v1.ax1x.com/2024/08/01/7GVnnP.jpg,
https://v1.ax1x.com/2024/08/01/7GVMLw.jpg`
]

// for (let key in productInfo) {
//   for (let k in productInfo[key]) {
//     let item = productInfo[key][k]
//     if (k.indexOf("Info") > -1 && Array.isArray(item)) {
//       // console.log(key, k, JSON.stringify(item))
//       const ke = k.replace("Info", "").replace(/\d/g, '');
//       const match = k.match(/\d+/); // 使用正则表达式匹配数字
//       const n = match ? match[0] : '';
//       let data = {
//         id: productInfo[key].id,
//         ['info' + n + '_' + ke]: JSON.stringify(item)
//       }
//       // console.log(data)
//       // POST("/main/saveProduct", data).catch((err) => {
//       //   console.log(key, k, '错误', err)
//       // })
//     }
//   }
//   // console.log('end')
// }