<template>
  <div class="product-view">
    <div v-if="product.name" class="banner canvas-box">
      <div class="banner-bg">
        <img id="img" />
      </div>

      <!-- <canvas id="hero-lightpass"></canvas> -->

      <h1 class="name">
        <b v-html="product['name_' + lang]"></b>
        <p v-html="product['desc_' + lang]"></p>
      </h1>
      <div class="ripple">
        <div class="A">A</div>
        <div v-if="lang == 'zh'" class="ripple-content">
          <b>{{ product.titleA }}</b>
          <p class="small" v-html="product.contentA"></p>
        </div>

        <div v-else class="ripple-content">
          <b>{{ product.titleA_en }}</b>
          <p class="small" v-html="product.contentA_en"></p>
        </div>
      </div>

      <div class="ripple">
        <div class="B">B</div>
        <div v-if="lang == 'zh'" class="ripple-content">
          <b>{{ product.titleB }}</b>
          <p class="small" v-html="product.contentB"></p>
        </div>

        <div v-else class="ripple-content">
          <b>{{ product.titleB_en }}</b>
          <p class="small" v-html="product.contentB_en"></p>
        </div>
      </div>
    </div>
    <!-- 水波纹标注 -->
    <div class="page-content">
      <h1
        v-if="!product.name"
        v-html="product['name_' + lang]"
        class="title"
      ></h1>
      <div v-if="!product.name" class="line-divider"></div>
      <p class="desc" v-html="product['desc_' + lang]"></p>
      <!-- 产品特性 -->
      <template
        v-if="
          product['info_' + lang] || product['info1_' + lang] || product.img1
        "
      >
        <h1 class="title">{{ $t("product.subTitle1") }}</h1>
        <div class="line-divider"></div>

        <div class="form-box" v-if="product['info_' + lang]">
          <template v-for="(item, idx) in product['info_' + lang]">
            <div v-if="item.content" :key="idx" class="form-item">
              <label class="text-primary">{{ item.title }}</label>
              <span class="text-primary" v-html="item.content"></span>
            </div>
          </template>
        </div>
        <el-image v-if="product.img1" class="img1" :src="path + product.img1">
        </el-image>
        <div class="grid" v-if="product['info1_' + lang]">
          <div
            class="grid-item"
            v-for="(item, idx) in product['info1_' + lang]"
            :key="idx"
          >
            <div class="grid-item-body">
              <h3 v-html="item.title"></h3>
              <div class="text-box" v-html="item.content"></div>
              <div class="text-box" v-html="item.content1"></div>
            </div>
          </div>
        </div>
      </template>
      <!-- 原料特性 -->
      <template v-if="product['info2_' + lang] || product.img2">
        <h1 class="title">{{ $t("product.subTitle2") }}</h1>
        <div class="line-divider"></div>
        <div class="info" v-if="product['info2_' + lang]">
          <h4
            class="text-primary"
            v-html="product['info2_' + lang][0].title"
          ></h4>
          <p v-html="product['info2_' + lang][0].content"></p>
        </div>
        <div v-if="product.img2" class="img2">
          <el-image :src="path + product.img2"> ></el-image>
        </div>
        <div class="tools">
          <div class="tools-item" v-for="(item, idx) in tools" :key="idx">
            <el-image :src="require('@/assets/img/' + item.img)" />
            <p v-html="lang == 'zh' ? item.name : item.label"></p>
          </div>
        </div>
      </template>
      <!-- 材料 -->
      <div class="material" v-if="product['info3_' + lang]">
        <div
          class="material-item"
          v-for="(item, idx) in product['info3_' + lang]"
          gutter="60"
          :key="idx + ','"
        >
          <h5 class="text-primary" v-html="item.title"></h5>
          <div class="material-item-body">
            <div class="text-box" v-html="item.content"></div>
            <div class="text-box" v-html="item.content1"></div>
          </div>
        </div>
      </div>
      <!-- 原料性能 -->
      <template v-if="product['info4_' + lang] || product['info5_' + lang]">
        <h1 class="title">{{ $t("product.subTitle3") }}</h1>
        <div class="line-divider"></div>
        <div class="info" v-if="product['info4_' + lang]">
          <h4 class="text-primary" v-html="product['info4_' + lang]"></h4>
        </div>
        <!-- 雷达图 -->
        <div class="chart-box">
          <el-col
            class="chart-item"
            :sm="24"
            :span="(1 / product['info5_' + lang].length) * 24"
            :key="idx"
            v-for="(item, idx) in product['info5_' + lang]"
          >
            <div class="chart-header" v-html="item.title"></div>
            <div v-if="item.content" class="chart-img">
              <img :src="path + item.content" />
            </div>
          </el-col>

          <!-- <div class="chart-item">
            <div class="chart-header">Type I Glass</div>
            <div class="chart" id="chart1"></div>
          </div>
          <div class="chart-item">
            <div class="chart-header">Type I Glass</div>
            <div class="chart" id="chart2"></div>
          </div>
          <div class="chart-item">
            <div class="chart-header">COP</div>
            <div class="chart" id="chart3"></div>
          </div> -->
        </div>
      </template>
    </div>
  </div>
</template>

<script>
// imgs
import { productInfo } from "./view.js";
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
gsap.registerPlugin(ScrollTrigger);
const indicator = [
  { name: "Transparency", max: 5 },
  { name: "Low cost", max: 5 },
  { name: "Chemical\nresistance", max: 5 },
  { name: "Water barrier", max: 5 },
  { name: "Oxygen\nbarrier", max: 5 },
  { name: "Sterilization", max: 5 },
  { name: "Protein\nabsorbtion", max: 5 },
  { name: "pH\nchange", max: 5 },
  { name: "Breakability", max: 5 },
];
export default {
  data() {
    return {
      path: config.BASEURL,
      info: [
        {
          zh: "可选规格",
          en: "Specification",
        },
        {
          zh: "设计要求",
          en: "Design According to",
        },
        {
          zh: "材质",
          en: "Material",
        },
        {
          zh: "润滑方式",
          en: "Lubrication",
        },
        {
          zh: "灭菌方式",
          en: "Sterlization",
        },
        {
          zh: "产品应用",
          en: "Applications",
        },
      ],
      product: {
        info_zh: [],
        info_en: [],
        info1_zh: [],
        info1_en: [],
        info2_zh: [],
        info2_en: [],
        info3_zh: [],
        info3_en: [],
        info4_zh: "",
        info4_en: "",
        info5_zh: [],
        info5_en: [],
      },
      tools: [
        {
          label: "Ultra high<br/> Structure Strength",
          name: "超高<br/>结构强度",
          img: "product-icon1.png",
        },
        {
          label: "Ultra low <br/> protein adsorption",
          name: "超低蛋白<br/>吸附",
          img: "product-icon2.png",
        },
        {
          label: "Ultra Low <br/>  Extractable & leachables",
          name: "超低溶出<br/>析出",
          img: "product-icon3.png",
        },
        {
          label: "Ultra Low  pH <br/> Shift",
          name: "超低pH<br/>偏移",
          img: "product-icon4.png",
        },
        {
          label: "Deep Cold <br/> Storage(-196°C)",
          name: "耐超低温<br/>冻存(-196度)",

          img: "product-icon5.png",
        },
        {
          label: "Stable <br/> Supply Chain",
          name: "材料<br/>供应稳定",
          img: "product-icon6.png",
        },
      ],
      productInfo,
    };
  },
  computed: {
    lang() {
      return this.$i18n.locale;
    },
  },
  created() {
    let id = this.$route.path.split("/");
    this.id = id[id.length - 1];
    id = this.id;
    // this.product = this.productInfo[this.id];
    this.getViewBylistsId(id);
  },
  mounted() {
    // window.scrollTo(0, 500);
    setTimeout(() => {
      document.querySelector("html").scrollTop = 0;
    }, 100);
    setTimeout(() => {
      // this.echartInit1();
      // this.echartInit2();
      // this.echartInit3();
      // setTimeout(() => {
      //   this.chart1 && this.exportChart(this.chart1);
      //   this.chart2 && this.exportChart(this.chart2);
      //   this.chart3 && this.exportChart(this.chart3);
      // }, 1000);
      window.onresize = () => {
        window.location.reload();
        // setTimeout(() => {
        //   this.chart1.resize();
        //   this.chart2.resize();
        //   this.chart3.resize();
        //
        // }, 500);
      };
    }, 1000);
  },
  methods: {
    exportChart(chart) {
      // 转换 ECharts 实例为 canvas 元素
      // var canvas =  chart.getDataURL({
      //   type: "png",
      //   pixelRatio: 2,
      // });

      // 创建一个图片元素
      var img = new Image();
      img.src = chart.getDataURL({
        type: "png",
        pixelRatio: 2,
      });

      // 插入到 DOM 中，例如 body
      document.body.appendChild(img);

      // 或者下载图片
      var link = document.createElement("a");
      link.download = "echarts-export.png";
      link.href = img.src;
      link.click();
    },
    getViewBylistsId(id) {
      this.loading = true;
      return this.$api.getViewBylistsId(id).then((res) => {
        // console.log(res.data, "getView");
        this.loading = false;
        const arrKeys = [
          "info_zh",
          "info_en",
          "info1_zh",
          "info1_en",
          "info2_zh",
          "info2_en",
          "info3_zh",
          "info3_en",
          "info5_zh",
          "info5_en",
        ];
        for (let key in res.data) {
          if (arrKeys.includes(key)) {
            this.product[key] = res.data[key]
              ? JSON.parse(res.data[key])
              : null;
          } else {
            this.product[key] = res.data[key];
          }
        }
        // console.log(this.product, "getViewBylistsId");
        setTimeout(() => {
          this.product.name && this.gsapInit();
        }, 200);
        // console.log(this.product, "info");
        return res;
      });
    },
    gsapResize() {
      //   let windowWidth = window.innerWidth;
      //   let windowHeight = window.innerHeight;
      // 当窗口大小改变时，更新动画
      let width = window.innerWidth;
      this.t1.duration(width / 100); // 根据窗口宽度调整动画时间
    },
    gsapInit() {
      // ScrollTrigger.saveStyles(".container-hero div");
      let images = {};
      // const canvas = document.getElementById("hero-lightpass")
      const _this = this;
      ScrollTrigger.matchMedia({
        "(min-width: 1260px)": function () {},
        // mobile
        "(max-width: 780px)": function () {},
        "(max-width: 680px)": function () {},

        // all
        all: function () {
          // const context = canvas.getContext("2d")
          // canvas.width = window.innerWidth
          let head =
            window.innerWidth > 1260
              ? document.querySelector(".head")
              : document.querySelector(".mobile-head");
          document.querySelector(".banner-bg").style.top =
            head.offsetHeight + "px";
          console.log(head, "gsapInit");
          const frameCount = 60;

          let id = _this.product.name.match(/\d+/);
          id = id ? id[0] : "";
          let loc = window.location;
          const currentFrame = (index) => {
            let n = "0";
            if (index < 10) {
              n += index + 1;
            } else {
              n = index + 1;
            }
            return `${loc.origin}${loc.pathname}${id}/${n}.jpg`;
          };
          const airpods = {
            frame: 0,
          };

          for (let i = 1; i < frameCount; i++) {
            const img = new Image();
            img.src = currentFrame(i);
            if (!images[_this.id]) images[_this.id] = [];
            images[_this.id].push(img);
          }
          gsap.to(airpods, {
            frame: frameCount - 1,
            snap: "frame",
            scrollTrigger: {
              scrub: 0.5,
            },
            onUpdate: render,
          });
          if (images[_this.id]) images[_this.id][0].onload = render;
          function render() {
            let id = _this.$route.path.split("/");
            id = id[id.length - 1];
            // console.log(id, images)
            if (airpods.frame > frameCount) {
              return;
            }
            let box = document.querySelector("#img");
            if (
              images[id] &&
              images[id][airpods.frame] &&
              images[id][airpods.frame].src
            )
              box.src = images[id][airpods.frame + 2].src;
          }
          var tl = gsap.timeline({
            scrollTrigger: {
              scrub: true,
            },
          });
          var scrollTop = 0;
          // 水波纹
          // var rippleContent = document.querySelectorAll(".ripple-content")
          tl.to(".ripple .A,.ripple .B", { scale: 1, duration: 0.2 }, 0)
            .to(".name", { opacity: 0, display: "none" }, 0.5)
            .to(".ripple .A,.ripple .B", { scale: 0, duration: 0.2 }, 1)
            .from(
              "#img",
              {
                scale: 1.2,
                duration: 1,
              },
              0
            )
            .to(
              "#img",
              {
                scale: 1,
                duration: 0.25,
              },
              1.5
            )
            .to(
              ".banner-bg",
              {
                duration: 0.5,
                top() {
                  scrollTop =
                    document.querySelector("html").scrollTop +
                    document.querySelector(".banner-bg").offsetHeight;
                  // console.log(scrollTop)
                  document.querySelector(".page-content").style.paddingTop =
                    scrollTop + "px";
                  return "-100%";
                },
              },
              1.3
            )
            .to(
              ".banner-bg",
              {
                duration: 0.2,
                opacity: 0,
              },
              1.4
            );
          // .to(
          //   ".page-content ",
          //   {
          //     top() {
          //       console.log(11,scrollTop)
          //       return scrollTop
          //     },
          //   },
          //   1.3
          // )
        },
      });
      // this.t1 = t1;
    },
    echartInit1() {
      var chartDom = document.getElementById("chart1");
      var myChart = this.$echarts.init(chartDom);
      var option = {
        radar: {
          radius: "60%",

          axisName: {
            color: "#231815",
          },
          indicator,
        },
        series: [
          {
            type: "radar",
            symbol: "none",
            lineStyle: {
              color: "#79bf42",
            },
            data: [
              {
                value: [5, 3, 4, 4, 3, 4, 5, 5, 3.5],
              },
            ],
          },
        ],
      };
      option && myChart.setOption(option);
      this.chart1 = myChart;
    },
    echartInit2() {
      var chartDom = document.getElementById("chart2");
      var myChart = this.$echarts.init(chartDom);
      var option = {
        radar: {
          radius: "60%",

          axisName: {
            color: "#231815",
          },
          indicator,
        },
        series: [
          {
            type: "radar",
            symbol: "none",
            lineStyle: {
              color: "#e7161a",
            },
            data: [
              {
                value: [5, 3, 5, 5, 5, 4, 3, 3, 2],
              },
            ],
          },
        ],
      };
      option && myChart.setOption(option);

      this.chart2 = myChart;
    },
    echartInit3() {
      var chartDom = document.getElementById("chart3");
      var myChart = this.$echarts.init(chartDom);
      var option = {
        radar: {
          radius: "60%",

          axisName: {
            color: "#231815",
          },
          indicator,
        },
        series: [
          {
            type: "radar",
            symbol: "none",
            lineStyle: {
              color: "#353270",
            },
            data: [
              {
                value: [5, 1, 4, 4, 3, 4, 5, 5, 5.2],
              },
            ],
          },
        ],
      };
      option && myChart.setOption(option);

      this.chart3 = myChart;
    },
  },
};
</script>
<style>
.grid-item-body img {
  margin-top: 2px;
  height: 22px;
  object-fit: cover;
}
</style>
<style lang="scss">
.product-view {
  overflow: hidden;
  .page-content {
    position: relative;
    // position: fixed;
    top: 0px;
    left: 0px;
    padding-top: 40px;
    z-index: -1;
    .desc,
    .desc * {
      font-weight: 300;
      font-size: var(--font-size);
    }
    .title {
      margin-top: 120px;
      margin-bottom: 40px;
      font-size: calc(var(--font-size) * 1.6);
      color: $--color-primary;
      * {
        font-size: calc(var(--font-size) * 1.6);
        color: $--color-primary;
      }
    }
    .line-divider {
      margin-bottom: 40px;
    }
  }
  .img1 {
    margin-bottom: 20px;
  }
  .img2 {
    text-align: center;
    margin-bottom: 120px;
 height: 600px;
    // display: flex;
    img,.el-image{
      height: 100%;
      width: 100%;
      object-fit: contain;
    }
  }
  .tools {
    display: flex;
    margin-bottom: 120px;
    .tools-item {
      flex: 1;
      justify-content: center;
      text-align: center;
    }
    .el-image {
      width: 80px;
      height: 80px;
      margin-bottom: 30px;
      img {
        object-fit: contain;
      }
    }
  }
  .info {
    margin-bottom: 100px;
    h4,
    h4 * {
      font-size: var(--font-size);
      margin-bottom: 20px;
    }
    p,
    p * {
      font-size: calc(var(--font-size) - 4px);
    }
  }
  .material {
    margin-bottom: 120px;
    .material-item {
      & + .material-item {
        margin-top: 78px;
      }
      .material-item-body {
        display: flex;
        align-items: end;
        margin-left: -64px;
        margin-right: -64px;
        .text-box {
          flex: 1;
          padding-left: 64px;
          padding-right: 64px;
          font-size: calc(var(--font-size) - 12px);
          color: #4d4d4d;
          line-height: 30px;
          * {
            font-size: calc(var(--font-size) - 12px);
          }
        }
      }
    }
    .text-primary {
      font-weight: 400;
      font-size: calc(var(--font-size) - 6px);
      margin-bottom: 30px;
      * {
        font-weight: 400;
        font-size: calc(var(--font-size) - 6px);
      }
    }
  }
  .grid {
    display: flex;
    flex-wrap: wrap;
    margin-left: -20px;
    margin-right: -20px;
    .grid-item {
      width: 50%;
      padding: 20px;
      .grid-item-body {
        height: 100%;
        padding: 40px;
        background-color: #f7f7f7;
        h3 {
          font-weight: 600;
          font-size: calc(var(--font-size) + 6px);
          color: $--color-primary;
          margin-bottom: 20px;
          * {
            font-size: calc(var(--font-size) + 6px);
            color: $--color-primary;
          }
        }
        p,
        p * {
          font-size: calc(var(--font-size) - 4px);
        }
        .text-box + .text-box {
          margin-top: 38px;
        }
      }
    }
  }
  .chart-box {
    display: flex;
    .chart-item {
      width: 100%;
      .chart-header {
        font-size: calc(var(--font-size) + 4px);
        color: $--color-primary;
        font-weight: 600;
        margin-bottom: 6px;
        text-align: center;
        * {
          font-size: calc(var(--font-size) + 4px);
          color: $--color-primary;

          text-align: center;
        }
      }
      .chart {
        width: 100%;
        height: 300px;
        margin-bottom: 20px;
      }
      .chart-img {
        height: 300px;
        margin-bottom: 20px;
        img {
          width: 100%;
          height: 100%;
          object-fit: contain;
        }
      }
    }
  }
  .form-box {
    margin-bottom: 80px;
    .form-item {
      margin-bottom: 30px;
      font-weight: 500;
      display: flex;
      label {
        font-size: calc(var(--font-size) - 4px);
        font-weight: 600;
        display: inline-block;
        margin-right: 10px;
        * {
          font-size: calc(var(--font-size) - 4px);
          font-weight: 600;
        }
        &:after {
          content: ":";
        }
      }
      span,
      span * {
        font-size: calc(var(--font-size) - 4px);
        font-weight: 300;
      }
    }
  }
}
</style>
