import { POST, GET } from "./http"
// , GET, DElETE
export default {
  saveUser: (data) => POST("/main/saveUser", data),
  getUserById: (data) => POST("/main/userById", data),

  saveAdmin: (data) => POST("/main/saveAdmin", data),
  getNews: (data) => GET("/main/newsList", data),
  getNewsById: (data) => POST("/main/getNewsById", data),
  saveNews: (data) => POST("/main/saveNews", data),
  deleteNews: (id) => POST("/main/deleteNews", { id }),
  // 产品描述,
  productList: (data) => GET("/main/productList", data),
  productById: (data) => POST("/main/getProduct", data),
  productSave: (data) => POST("/main/saveProduct", data),
  getViewBylistsId: (listsId) => POST("/main/productBylistsId", { listsId }),
  // 产品管理
  getLists: (data) => POST("/main/lists", data),
  listsSave: (data) => POST("/main/listsave", data),
  listsById: (data) => POST("/main/listsById", data),
  deleteLists: (id) => POST("/main/deleteLists", { id }),
  // 管理系统
  login: (data) =>
    POST("/main/login", data).then((res) => {
      if (res.code === 200) {
        sessionStorage.setItem("user", JSON.stringify(res.data))
      }
      return res
    }),
  // 获取用户信息
  getUserList: () => GET("/main/userList"),
  // 上传文件
  uploadFile: (data) => POST("/main/uploads", data),
  uploadBanner: (data) => POST("/main/uploadBanner", data),
  getBanner: () => GET("/main/banner"),

  // 获取公司信息
  saveCompany: (data) => POST("/main/saveCompany", data),
  getCompany: () => GET("/main/company"),
  // 数据导出
  exportData: (data) => POST("/main/exportExcel", data,{responseType: 'blob'}),
}
