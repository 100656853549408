/**
 * 请求配置
 */
import axios from "axios"
let msgBox = require("element-ui").Message

// axios默认配置
axios.defaults.timeout = 1000000 // 10000000
axios.defaults.baseURL = config.BASEURL //config.BASEURL
// console.log( config.BASEURL)
// **路由请求拦截**
// http request 拦截器
axios.interceptors.request.use(
  (config) => {
    let token = sessionStorage.getItem("token")
    token && (config.headers["Authorization"] = "ticket")
    if (token && token != "undefined") {
      config.headers["Authorization"] = token
    }

    if (!config.headers["Content-Type"]) {
      config.headers["Content-Type"] = "application/json;charset=UTF-8"
    }
    // config.headers['Access-Control-Allow-Origin'] = '*'
    // config.headers['Access-Control-Allow-Headers'] = '*'
    return config
  },
  (error) => {
    console.log(error)
    return Promise.reject(error.response)
  }
)

// **路由响应拦截**
// http response 拦截器
axios.interceptors.response.use(
  (response) => {
    /* 文件流 */
    if (
      response.config.responseType === "blob" ||
      response.config.url.indexOf(".svg") > -1
    ) {
      return response
    }
    const data = response.data
    var status = data.code
    // console.log(data)
    if (status === 200 || status == 0) return data
    msgBox({ type: "error", message: data.data })
    if (status === 3) {
      // token 失效
      // 清除session 返回登录
      sessionStorage.clear()
      //  router.push("./login")
    }
    return Promise.reject(data)
  },
  ({ response }) => {
    msgBox({
      message:
        (response && response.config.url + ":") +
        (response.data.message || "获取错误"),
      type: "error",
    })
    return Promise.reject(response) // 返回接口返回的错误信息
  }
)

const HTTP = (method, url, data, config) => {
  const dataKey = method != "get" ? "data" : "params"
  return axios({
    method,
    url,
    [dataKey]: data,
    ...config,
  })
}
export const POST = (url, data, config) => {
  return HTTP("post", url, data, config)
}
export const GET = (url, params, config) => {
  return HTTP("get", url, params, config)
}
export const DElETE = (url, data, config) => {
  return HTTP("delete", url, data, config)
}
export default axios
