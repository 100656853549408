<template>
  <div class="page-content home">
    <el-backtop></el-backtop>
    <div
      v-for="(item, index) in moduleInfo"
      class="module-item"
      :key="'_' + index"
    >
      <div class="link">
        <div class="link-btn">
          <h3>{{ $t(item.title) }}</h3>
          <el-button type="primary" round @click="openPage(item.link)">
            {{ $t("home.learnMore") }}
            <i class="el-icon-arrow-right"></i>
          </el-button>
        </div>
        <el-image class="img" :src="item.img"></el-image>
      </div>
      <!-- <p>{{ $t(item.desc) }}</p> -->
    </div>
    <div
      v-for="(item, index) in msgList"
      class="module-item2"
      :key="index + '_'"
    >
      <el-image class="img" :src="path + item.img"></el-image>
      <div class="item-main">
        <h4>{{ $i18n.locale == "zh" ? item.title_zh : item.title_en }}</h4>
        <div
          class="p"
          v-html="$i18n.locale == 'zh' ? item.content_zh : item.content_en"
        ></div>
      </div>
      <el-link type="primary" :underline="false" @click="openPage(item.link)">
        {{ $t("home.readMore") }}<i class="el-icon-arrow-right"></i>
      </el-link>
    </div>
  </div>
</template>

<script>
import { formatDate } from "@/utils/index";
export default {
  name: "home-page",
  data() {
    return {
      path: config.BASEURL,
      typeOptions: [
        { name: "International News", value: "国际新闻" },
        { name: "Company News", value: "公司新闻" },
      ],
      moduleInfo: [
        {
          title: "home.product",
          desc: "home.productDesc",
          link: "/product",
          img: "home1.png",
        },
        {
          title: "home.company",
          desc: "home.companytDesc",
          link: "/access",
          img: "home2.png",
        },
        // {
        //   title: "home.other1",
        //   desc: "home.otherDesc1",
        //   link: "/product/view",
        //   img: "home3.png",
        // },
        // {
        //   title: "home.other2",
        //   desc: "home.otherDesc2",
        //   link: "/product",
        //   img: "home4.png",
        // },
        // {
        //   title: "home.other3",
        //   desc: "home.otherDesc3",
        //   link: "/contact",
        //   img: "home5.png",
        // },
        // {
        //   title: "home.msg",
        //   desc: "home.newsMore",
        //   link: "/news",
        //   img: "home6.png",
        // },
      ],
      msgList: [],
    };
  },
  created() {
    this.getList();
    this.init();
  },
  methods: {
    init() {
      this.$api.getBanner().then((res) => {
        if (res.data) {
          res.data.forEach((val) => {
            let opt = this.moduleInfo.find((item) => item.title == val.page);
            if (opt) {
              opt.img = config.BASEURL + val.img;
            }
          });
        }
      });
      this.$api.getLists({ page: "adminHome" }).then((res) => {
        if (res.data) {
          this.msgList = res.data;
        }
      });
    },
    formatDate,
    getType(type) {
      if (!type) return this.$t("other");
      let opt = this.typeOptions.find(
        (item) => item.value == type || item.name == type
      );
      return this.$i18n.locale == "zh" ? opt.value : opt.name;
    },
    getList() {
      // this.$api.getNews().then((res) => {
      //   if (!res.data) return;
      //   this.msgList = res.data.splice(0, 10);
      // });
    },
    openPage(path) {
      this.$router.push(path);
    },
  },
};
</script>
<style lang="scss">
.page-content.home {
  .msg-list {
    margin-top: 120px;
    margin-bottom: 50px;
  }

  .module-item2 {
    display: flex;
    align-items: center;
    padding-top: 50px;
    padding-bottom: 50px;
    position: relative;
    @include dashed-line;
    .img {
      width: 84px;
      min-width: 84px;
      height: 78px;
      margin-right: 46px;
    }
    .item-main {
      width: 100%;
      padding-right: 20px;
      h4 {
        font-size: calc(var(--font-size) + 6px);
        font-weight: 600;
        margin-bottom: 16px;
      }
      .p,
      .p * {
        font-size: calc(var(--font-size) - 2px);
      }
    }
  }
  .el-link  .el-link--inner {
    font-weight: 400;
    font-size: var(--font-size);
    white-space: nowrap;
    i {
      font-size: calc(var(--font-size) - 4px);
    }
  }
}
</style>
