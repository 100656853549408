<template>
  <li v-if="!meta.isHide" class="menu-item">
    <el-menu-item
      v-if="!router.menus && !router.children"
      :index="router.url || router.path"
      :key="router.url || router.path"
    >
      <slot name="itemTitle">
        <i class="icon" :class="`${router.icon || meta.icon}`"></i>
        <template v-if="router['title_' + $i18n.locale]">
          <p class="title" v-html="router['title_' + $i18n.locale]"></p>
          <p v-if="router['subTitle_' + $i18n.locale]">
            {{ router["subTitle_" + $i18n.locale] }}
          </p>
        </template>
        <template v-else>
          <p class="title" v-html="$t(`${router.name}.title`)"></p>
          <p v-if="meta.subTitle">{{ $t(`${router.name}.subTitle`) }}</p>
        </template>
      </slot>
    </el-menu-item>
    <!-- <ul class="submenuTitle" v-if="router.menus || router.children">
      <el-menu-item
        :index="item.url || item.path"
        :key="item.url || item.path"
        v-for="item in router.menus || router.children"
      >
        <slot name="submenuTitle">
          <i class="icon" :class="`${item.icon || meta.icon}`"></i>
          <span>{{ item.menuName || item.title }}</span>
        </slot>
      </el-menu-item>
    </ul> -->
    <el-submenu
      popper-class="submenu-full"
      v-else
      :index="router.menuName || meta.title"
    >
      <slot name="title" slot="title">
        <span
          :class="{ active: $route.path == router.path }"
          @click="openSub(router)"
        >
          <i
            v-if="router.icon || meta.icon"
            class="icon"
            :class="`${router.icon || meta.icon}`"
          ></i>
          <p class="title">
            {{ router.menuName || $t(`${router.name}.title`) }}
          </p>
          <p class="title" v-if="meta.subTitle">
            {{ $t(`${router.name}.subTitle`) }}
          </p>
        </span>
      </slot>
      <subMenu
        v-for="item in router.menus || router.children"
        :router="item"
        :key="item.url"
      ></subMenu>
    </el-submenu>
  </li>
</template>

<script>
export default {
  name: "subMenu",
  props: {
    /* 是否打开父级 */
    isOpenParent: Boolean,
    index: [String, Number],
    router: {
      type: Object,
      default: () => {
        return {};
      },
    },
  },
  data() {
    return {};
  },
  computed: {
    meta() {
      return this.router.meta || {};
    },
  },
  mounted() {},
  methods: {
    openSub(router) {
      if (router.meta.isOpen && this.isOpenParent) {
        this.$router.push(router.path);
      }
      this.$emit("select", router.path, router);
    },
    hasArrow() {
      const { menus } = this.router;
      let boon = menus.some((val) => !val.meta.hidden && !val.meta.isHide);
      return !boon;
    },
  },
};
</script>

<style lang="scss">
.submenu-full {
  width: 100%;
  left: 0px !important;
  .el-menu--popup {
    box-shadow: 0px 5px 9px 1px rgba(56, 51, 117, 0.15);
  }

  .el-menu--popup-bottom-start {
    margin-top: 0px;
  }
  .el-menu {
    padding: 0px;
    display: flex;
    justify-content: center;
    background: #f5f5f5;
    .menu-item {
      float: none;
    }
    .el-menu-item {
      height: auto;
      text-align: center;
      padding-left: 56px;
      padding-right: 56px;
      padding-top: 20px;
      padding-bottom: 20px;
      background: transparent;
      flex-direction: column;
      color: $--color-primary;
      .title::after {
        display: none;
      }
      &.is-active,
      &:hover {
        color: $--color-reverse !important;
        background: $--color-primary !important;
      }
    }
  }
}
</style>
<style lang="scss">
.el-menu--horizontal {
  .el-menu-item,
  .el-submenu {
    .icon {
      // margin-right: 0px;
      position: relative;
      top: -2px;
      left: -2px;
    }
  }
  .menu-item {
    float: left;
    height: 100%;
    display: flex;
    align-items: center;
  }
  &.el-menu .el-menu-item {
    height: 100%;
    display: flex;
    align-items: center;
    .title {
      position: relative;
      font-size: calc(var(--font-size) - 4px);
      &::after {
        content: "";
        position: absolute;
        bottom: 0px;
        left: 0;
        width: 100%;
        height: 2px;
        background-color: transparent;
      }
    }
    &.is-active,
    &:hover {
      color: $--color-primary;
      background-color: $--menu-item-hover-fill;
      .title::after {
        background-color: $--color-primary;
      }
      .icon {
        color: $--color-primary;
      }
    }
  }

  .el-submenu {
    height: 100%;
    display: flex;
    align-items: center;

    .el-submenu__title {
      height: 100%;
      display: flex;
      align-items: center;
      .title {
        position: relative;
        font-size: calc(var(--font-size) - 4px);
        &::after {
          content: "";
          position: absolute;
          bottom: 0px;
          left: 0;
          width: 100%;
          height: 2px;
          background-color: transparent;
        }
      }
      &.is-active,
      &:hover,
      .active {
        color: $--color-primary;
        background-color: $--menu-item-hover-fill;
        .title::after {
          background-color: $--color-primary;
        }
        .icon {
          color: $--color-primary;
        }
      }
      .el-submenu__icon-arrow {
        display: none;
      }
    }
  }

  .is-arrow {
    .el-submenu__icon-arrow {
      display: none;
    }
  }
}
</style>
