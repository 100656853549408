<template>
  <el-footer class="footer">
    <div class="footer-list">
      <img class="logo" :src="path + data.logo1" @click="$router.push('/')" />
      <div class="footer-1" @click="$router.push('/')">
        <h4>{{ $t("footerTitle") }}</h4>
        <p>
          Butterfly Pharmaceutical Packaging Co., Ltd.<br />
          Crystal BioPure Pre-filled Syringe System <br />
          Made by Elaborate Process
        </p>
      </div>
      <div class="footer-2">
        <h4>{{ $t("product.title") }}</h4>

        <template v-for="(router, idx) in routers">
          <!-- <h4 :key="router.path" v-html="router['title_' + $i18n.locale]"></h4> -->
          <router-link v-if="idx < 3" :key="router.path" :to="router.path">
            <span v-html="router['subTitle_' + $i18n.locale]"></span>
          </router-link>
        </template>
        <router-link to="/product">
          {{ $t("more") }}
        </router-link>
      </div>
      <div class="footer-3">
        <h4>{{ $t("company.title") }}</h4>
        <router-link to="/news">{{ $t("news.title") }}</router-link>
        <router-link to="/contact">{{ $t("contact.title") }}</router-link>
      </div>
    </div>
    <el-divider></el-divider>
    <div class="footer-list">
      <div class="footer-1">
        <div class="flex">
          <i class="el-icon-location"></i>
          <p v-if="$i18n.locale == 'zh'">{{ data.address }}</p>
          <p v-else>{{ data.address_en }}</p>
        </div>
      </div>
      <div class="footer-2">
        <div class="flex">
          <i class="el-icon-phone"></i>
          <p>{{ data.phone }}</p>
        </div>
      </div>

      <div class="footer-3">
        <div class="flex">
          <i class="el-icon-message"></i>
          <p>{{ data.email }}</p>
        </div>
      </div>
    </div>
  </el-footer>
</template>

<script>
export default {
  props: {
    routers: {
      type: Array,
      default: () => {
        return {};
      },
    },
  },
  data() {
    return {
      path: config.BASEURL,
      data: {},
      // routerList: [],
    };
  },
  watch: {
    // routers(value) {
    //   if (!value) return;
    //   this.routerList = value;
    // },
  },
  created() {
    this.getCompany();
  },
  methods: {
    getCompany() {
      this.$api.getCompany().then((res) => {
        this.data = res.data;
      });
    },
  },
};
</script>
<style lang="scss" scoped>
.el-footer.footer {
  padding-top: 70px;
  padding-bottom: 70px;
  height: auto !important;
  background: $--color-primary;
  .footer-box {
    // display: flex;
    // // justify-content: center;
    // align-items: center;
    // flex-direction:column ;
  }
  .logo {
    width: 190px;
    height: 46px;
    position: absolute;
    top: 0px;
    left: 0px;
    cursor: pointer;
    object-fit: contain;
  }
  .el-divider {
    width: var(--page-width);
    margin-left: auto;
    margin-right: auto;
  }
  .footer-list {
    &:first-child {
      padding-top: calc(46px + 18px);
    }
    width: var(--page-width);
    margin: auto;
    display: flex;
    position: relative;
    .footer-1 {
      width: 440px;
      min-width: 440px;
      max-width: 440px;
      margin-right: 120px;
    }
    .footer-2,
    .footer-3 {
      width: calc((100% - 440px) / 2);
    }
    .footer-2 {
      margin-right: 90px;
    }
    .footer-1 .flex,
    .footer-2 .flex,
    .footer-3 .flex {
      display: flex;
      align-items: baseline;
    }
    .footer-3 .flex i {
      position: relative;
      top: 2px;
    }
    h4 {
      font-weight: 400;
      font-size: calc(var(--font-size) - 6px);
      color: #fffffe;
      line-height: 40px;
    }
    a {
      display: block;
      &:hover {
        opacity: 1;
      }
    }
    p,
    a {
      font-weight: 300;
      font-size: calc(var(--font-size) - 8px);
      color: #fffffe;
      line-height: 36px;
      opacity: 0.6;
    }
    i {
      opacity: 0.6;
      color: #fffffe;
      & + p {
        margin-left: 10px;
        display: inline-block;
      }
    }
  }
}
</style>
