<template>
  <el-footer class="mobile-footer">
    <base-aside
      :defaultOpeneds="['产品']"
      mode="vertical"
      :active="$route.path"
      :routers="routers"
    >
    </base-aside>

    <div class="logo-info">
      <img class="logo" src="@/assets/img/logo2.png" @click="$router.push('/')"/>
      <h4>{{ $t("title") }}</h4>
      <p>
        Butterfly Pharmaceutical Packaging Co., Ltd.<br />
        Crystal BioPure Pre-filled Syringe System <br />
        Made by Elaborate Process
      </p>
      <div class="flex">
        <i class="el-icon-location"></i>
        <p>{{ $t("address") }}</p>
      </div>
      <div class="flex">
        <i class="el-icon-phone"></i>
        <p>(025)-57221187</p>
      </div>
      <div class="flex">
        <i class="el-icon-message"></i>
        <p>gaoxy@butterflypp.com</p>
      </div>
    </div>
  </el-footer>
</template>

<script>
import baseAside from "./aside"
export default {
  name: "mobileFooter",
  props: {
    routers: {
      type: Object,
      default: () => {
        return {}
      },
    },
  },
  components: {
    baseAside,
  },
}
</script>
<style lang="scss" scoped>
.el-footer.mobile-footer {
  padding-top: 70px;
  padding-bottom: 70px;
  height: auto !important;
  background: $--color-primary;
  .logo-info {
    margin-top: 60px;
    margin-bottom: 60px;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    .logo {
      width: 100%;
      object-fit: contain;
    }

    h4 {
      margin-top: 30px;
      margin-bottom: 30px;
      color: #fffffe;
      font-size: calc(var(--font-size) + 6px);
    }
    p,
    i {
      font-size: var(--font-size);
      color: #fffffe;
      opacity: 0.6;
    }
    p{
      margin-bottom: 10px;
    }
    i{
      margin-right: 20px;
    }
    .flex{
      align-items: center;
    }
  }
}
</style>
