<template>
  <div>
    <base-table :tableHeader="tableHeader" :responses="tableData">
      <template slot="btns">
        <el-button type="success" size="small" @click="open('add')"
          >新增</el-button
        >
        <el-button type="primary" size="small" @click="getList"
          >刷新</el-button
        >
      </template>
      <div class="rows-btn" slot="opt" slot-scope="{ row }">
        <el-link type="primary" size="small" @click="open('edit' , row.id)"
          >编辑</el-link
        >
        <el-link type="danger" size="small" @click="delRow(row.id)">
          删除
        </el-link>
      </div>
    </base-table>
  </div>
</template>

<script>
import { formatDate } from "@/utils"
export default {
  components: {  },
  data() {
    // const _this = this
    return {
      tableHeader: [
        {
          label: "序号",
          type: "index",
          width: 55,
        },
        {
          label: "标题",
          prop: "title",
          width: 300,
        },
        {
          label: "发布时间",
          prop: "creattime",
          render: (h, rows) => {
            return h("span", {}, formatDate(rows.creattime, true))
          },
        },
        {
          label: "发布者",
          prop: "author",
        },
        {
          label: "操作",
          slotName: "opt",
          fixed: "right",
          width: 200,
        },
      ],
      tableData: [],
    }
  },
  mounted() {
    this.getList()
  },
  methods: {
    getList() {
      this.$api.getNews().then((res) => {
        this.tableData = res.data
      })
    },
    open(type,id) {
      this.$router.push({name:'newsEdit', query:{type , id}})
    },
    delRow(id) {
      this.$confirm("确认删除？", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        // 执行删除操作
        this.$api.deleteNews(id).then(() => {
          this.getList()
          this.$message({
            type: "success",
            message: "删除成功",
          })
        })
      })
    },
  },
}
</script>

<style></style>
